<template>
    <video v-if="hasErrored && type === 1" :src="videoURL" />
    <img v-else :src="thumbnailURL" @error="thumbnailLoadError" />
</template>

<script>
export default {
    name: 'ImageThumbnail',
    props: {
        src: {
            type: String,
            required: true,
        },

        h: {
            type: [Number, null],
            default: 60,
        },
        w: {
            type: [Number, null],
            default: 60,
        },
        type: {
            type: Number,
            // Image: 0, Video: 1, Title: 2
            default: 0,
        },
    },
    data() {
        return {
            hasErrored: false,
        };
    },
    computed: {
        transforms() {
            let transforms = [];
            if (this.$props.w) {
                transforms.push(`w-${this.$props.w}`);
            }
            if (this.$props.h) {
                transforms.push(`h-${this.$props.h}`);
            }
            if (this.imageType === 'tribute-template-renders') {
                transforms.push('so-2');
            }
            if (this.$props.type === 1) {
                return `tr=${transforms.join()}`;
            }
            return `tr:${transforms.join()}`;
        },
        imagePath() {
            switch (this.imageType) {
                case 'genre-images':
                    return this.imageType;
                case 'artwork-image':
                    return 'album-art';
                case 'original-tribute-photos':
                case 'tribute-photos':
                    return '';
                case 'tribute-template-renders':
                    return 'templates';
                default:
                    return '';
            }
        },
        imageType() {
            // Genre
            if (this.$props.src.includes('genre-image')) {
                return 'genre-images';
            }
            // Music Artwork
            if (this.$props.src.includes('artwork-image')) {
                return 'artwork-image';
            }
            // OG Tribute Photo
            if (this.$props.src.includes('original-tribute-photos')) {
                return 'original-tribute-photos';
            }
            // Tribute Photo
            if (this.$props.src.includes('tribute-photos')) {
                return 'tribute-photos';
            }
            // Tribute Photo
            if (this.$props.src.includes('tribute-template-renders')) {
                return 'tribute-template-renders';
            }
        },
        suffix() {
            if (this.type === 1) {
                return '/ik-thumbnail.jpg';
            }
            return '';
        },
        thumbnailURL() {
            if (this.$props.src && this.imageType) {
                const imgPath = this.$props.src.split(`/${this.imageType}/`)[1];
                // Video Type Thumbnails require that the transforms be at the end e.g: `?so=`
                if (this.type === 1) {
                    return `${process.env.VUE_APP_IMG_KIT_BASE}${this.imagePath ? this.imagePath + '/' : ''}${imgPath}${this.suffix}?${this.transforms}`;
                }
                return `${process.env.VUE_APP_IMG_KIT_BASE}${this.imagePath ? this.imagePath + '/' : ''}${this.transforms}/${imgPath}${this.suffix}`;
            }

            return this.$props.src;
        },
        videoURL() {
            //Fallback for imgKit size limit errors
            //Returns src url with imgkit transoformations exlcludiing thumbnail conversion
            const videoTransforms = this.transforms + ',du-1,q-50';
            return this.thumbnailURL.replace(this.transforms, videoTransforms).replace(this.suffix, '');
        },
    },
    methods: {
        thumbnailLoadError(e) {
            if (!this.hasErrored) {
                e.target.src = this.$props.src;
                // Prevent infinite error loop
                this.hasErrored = true;
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
