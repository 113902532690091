import moment from 'moment';
const RENDER_DELAY_BUFFER = 10000
export default {
    namespaced: true,
    state: {
        id: null,
        eventId: null,
        storyEventId: null,
        storyEvent: null,
        loading: false,
        uploadingPhotos: false,
        photos: [],
        photosOptions: {
            pageNum: 0,
            pageSize: 24,
        },
        selectedPhotos: [],
        selectedTemplates: [],
        selectedSongs: [],
        age: null,
        deeplink: null,
        familyLink: null,
        deadline: null,
        mainPhotoId: null,
        mainPhotoUrl: null,
        faceUuid: null,
        render: null,
        firstName: null,
        lastName: null,
        birthDate: null,
        deathDate: null,
        totalPhotos: 0,
        slideDuration: 0,
        minPhotos: 1,
        incomingUploadCount: 0,
        endingType: 1,
        endingDuration: 0,
        videoSlidesDuration: 0,
        videoSlidesCount: 0,
        uploadsOnly: false,
        maxItems: 0,
        renderStarted: null,
        totalDuration: 0,
        renderCompleted: null,
        overlayDismissed: false,
        lastSyncedChange: null,
    },
    mutations: {
        updateUploadingPhotos(state, payload) {
            state.uploadingPhotos = payload;
        },
        updateGalleryItem(state, payload) {
            // Find the gallery item in the state by ID
            const foundItem = state.photos.find(item => item.id === payload.id);

            if (foundItem) {
                // Update the properties of the found item
                for (const key in payload.data) {
                    if (Object.hasOwnProperty.call(payload.data, key)) {
                        foundItem[key] = payload.data[key];
                        foundItem.refreshKey++;
                    }
                }
            }
        },
        updateTributeVideoPhotosOptions(state, payload) {
            // Checking if the payload values for pageNum and pageSize are numbers and are 0 or greater
            if (typeof payload.pageNum === 'number' && payload.pageNum >= 0) {
                state.photosOptions.pageNum = payload.pageNum;
            }

            if (typeof payload.pageSize === 'number' && payload.pageSize >= 0) {
                state.photosOptions.pageSize = payload.pageSize;
            }
        },
        updateTributeVideoSelectedSongs(state, payload) {
            state.selectedSongs = payload;
        },
        updateTributeVideoSelectedTemplates(state, payload) {
            state.selectedTemplates = payload;
        },
        updateTributeVideoSelectedPhotos(state, payload) {
            state.selectedPhotos = payload;
        },
        updateTributeVideoPhotos(state, payload) {
            state.photos = payload;
        },
        updateTributeVideo(state, payload) {
            Object.assign(state, payload);
        },
        setTributeVideoRenderState(state, payload) {
            state.renderStarted = payload.startTime;
        },
        resetTributeVideoState(state) {
            const originalState = {
                id: null,
                eventId: null,
                storyEventId: null,
                storyEvent: null,
                loading: false,
                photos: [],
                photosOptions: {
                    pageNum: 0,
                    pageSize: 24,
                },
                selectedPhotos: [],
                selectedTemplates: [],
                selectedSongs: [],
                age: null,
                familyLink: null,
                deeplink: null,
                mainPhotoId: null,
                mainPhotoUrl: null,
                faceUuid: null,
                render: null,
                firstName: null,
                lastName: null,
                birthDate: null,
                deathDate: null,
                totalPhotos: 0,
                slideDuration: 0,
                minPhotos: 1,
                incomingUploadCount: 0,
                endingType: 1,
                endingDuration: 0,
                videoSlidesDuration: 0,
                videoSlidesCount: 0,
                deadline: null,
                onlyUploads: false,
                maxItems: 0,
                renderStarted: null,
                totalDuration: 0,
                renderCompleted: null,
                overlayDismissed: false,
                lastSyncedChange: null,
            };
            Object.assign(state, originalState);
        },
    },
    actions: {
        updateUploadingPhotos({ commit }, payload) {
            commit('updateUploadingPhotos', payload);
        },
        updateGalleryItem({ commit }, payload) {
            commit('updateGalleryItem', payload);
        },
        updateTributeVideoPhotosOptions({ commit }, payload) {
            commit('updateTributeVideoPhotosOptions', payload);
        },
        updateTributeVideoSelectedSongs({ commit }, payload) {
            commit('updateTributeVideoSelectedSongs', payload);
        },
        updateTributeVideoSelectedTemplates({ commit }, payload) {
            commit('updateTributeVideoSelectedTemplates', payload);
        },
        updateTributeVideoSelectedPhotos({ commit }, payload) {
            commit('updateTributeVideoSelectedPhotos', payload);
        },
        updateTributeVideoPhotos({ commit }, payload) {
            commit('updateTributeVideoPhotos', payload);
        },
        updateTributeVideo({ commit }, payload) {
            commit('updateTributeVideo', payload);
        },
        resetTributeVideoState({ commit }) {
            commit('resetTributeVideoState');
        },
        dismissRenderOverlay({ commit }) {
            commit('updateTributeVideo', { overlayDismissed: true });
        },
    },
    getters: {
        minimumCreateRequirementsMet(state) {
            if (state?.selectedPhotos?.length < 1) {
                return false;
            }
            if (state?.selectedTemplates?.length < 1) {
                return false;
            }
            if (state?.selectedSongs?.length < 1) {
                return false;
            }
            return true;
        },
        topThreeSelectedPhotos(state) {
            return state.selectedPhotos.slice(0, 3);
        },
        topThreeSelectedSongs(state) {
            return state.selectedSongs.slice(0, 3);
        },
        selectedPhotoCount(state) {
            return state.selectedPhotos.length;
        },
        selectedSongCount(state) {
            return state.selectedSongs.length;
        },
        selectedTemplateCount(state) {
            return state.selectedTemplates.length;
        },
        // alias of Templates
        selectedThemeCount(state) {
            return state.selectedTemplates.length;
        },
        selectedTheme(state) {
            // assumes only one theme/template can beselected at once
            return state.selectedTemplates[0];
        },
        tributeIsRendering(state) {
            if (state.overlayDismissed) {
                return false;
            }
            if (state.renderStarted && !state.renderCompleted) {
                return moment().isBefore(moment(state.renderStarted).add(state.totalDuration, 'seconds'));
            }
            return false;
        },
        delayRenderCallByMilliseconds(state) {
            // Workaround a known issue where image changes aren't ready by the time you start a render, leading to a failed render
            // We're front-loading a 10 second buffer. So the user will see the loading UI, but render call won't happen until after that initial delay
            // to make this robust we'll need to make sure that the call is executed before a user tries to leave/refresh/etc.
            if (!state.lastSyncedChange) {
                return 0;
            }
            const now = moment(), then = moment(state.lastSyncedChange).add(RENDER_DELAY_BUFFER, 'milliseconds');
            // Take the lastSyncedChange timestamp and add the buffer to it, return the amount of delay or 0
            if (now.isBefore(then)) {
                return Math.ceil(then.diff(now, 'milliseconds', true));
            }
            return 0;
        },
    },
};
