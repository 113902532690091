<template>
    <div>
        <div class="action-bar">
            <div class="left-buttons">
                <SlideUpload
                    ref="uploaderBtn"
                    :videoAllowed="allowVideoUploads"
                    :maxFiles="maxFilesLeft"
                    :dropTarget="$props.dropTarget"
                    @change="tempFiles => $emit('temp-file-change', tempFiles)"
                    @filesAdded="$emit('files-added')"
                >
                    <template #activator="{ onClick }">
                        <button class="primary-button" @click="onClick">
                            <svg
                                class="icon"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M3.33337 13.333L7.15486 9.51152C7.80574 8.86065 8.86101 8.86064 9.51189 9.51152L13.3334 13.333M11.6667 11.6663L12.9882 10.3449C13.6391 9.69398 14.6943 9.69398 15.3452 10.3449L16.6667 11.6663M11.6667 6.66634H11.675M5.00004 16.6663H15C15.9205 16.6663 16.6667 15.9201 16.6667 14.9997V4.99967C16.6667 4.0792 15.9205 3.33301 15 3.33301H5.00004C4.07957 3.33301 3.33337 4.0792 3.33337 4.99967V14.9997C3.33337 15.9201 4.07957 16.6663 5.00004 16.6663Z"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            Add Media
                        </button>
                    </template>
                </SlideUpload>

                <button class="secondary-button" @click="$emit('add-text-slide')">
                    <svg
                        class="icon"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M3.33337 5H16.6667M3.33337 10H16.6667M3.33337 15H9.16671"
                            stroke="#6B7280"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    Add Text Slide
                </button>
            </div>

            <div class="right-buttons">
                <button
                    class="icon-button"
                    @click="toggleHeight"
                    :style="{
                        borderTopLeftRadius: '6px',
                        borderBottomLeftRadius: '6px',
                    }"
                >
                    <svg
                        :style="{
                            transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.5s',
                        }"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M4.16671 12.5L10 6.66667L15.8334 12.5"
                            stroke="#6B7280"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>
                <button class="icon-button" @click="downloadSelectedSlides">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M3.33337 13.3337L3.33337 14.167C3.33337 15.5477 4.45266 16.667 5.83337 16.667L14.1667 16.667C15.5474 16.667 16.6667 15.5477 16.6667 14.167L16.6667 13.3337M13.3334 10.0003L10 13.3337M10 13.3337L6.66671 10.0003M10 13.3337L10 3.33366"
                            :stroke="isAnyImageSelected ? '#6B7280' : '#d1d5db'"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>
                <!-- <button @click="$emit('move-slide-backward')" :disabled="disableMoveBack" class="icon-button">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M5.83333 13.3337L2.5 10.0003M2.5 10.0003L5.83333 6.66699M2.5 10.0003L17.5 10.0003"
                            :stroke="!disableMoveBack ? '#6B7280' : '#d1d5db'"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>
                <button @click="$emit('move-slide-forward')" :disabled="disableMoveForward" class="icon-button">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.1667 6.66699L17.5 10.0003M17.5 10.0003L14.1667 13.3337M17.5 10.0003L2.5 10.0003"
                            :stroke="!disableMoveForward ? '#6B7280' : '#d1d5db'"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button> -->
                <button @click="$emit('edit-active-slide')" :disabled="selectedSlides.length !== 1" class="icon-button">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M16.8898 3.11019L17.5969 2.40309V2.40309L16.8898 3.11019ZM5.41673 17.5296V18.5296C5.68194 18.5296 5.9363 18.4242 6.12383 18.2367L5.41673 17.5296ZM2.50006 17.5296H1.50006C1.50006 18.0819 1.94778 18.5296 2.50006 18.5296V17.5296ZM2.50006 14.5537L1.79295 13.8466C1.60542 14.0341 1.50006 14.2885 1.50006 14.5537H2.50006ZM14.6507 3.8173C15.0737 3.39423 15.7596 3.39423 16.1827 3.8173L17.5969 2.40309C16.3928 1.19897 14.4406 1.19897 13.2364 2.40309L14.6507 3.8173ZM16.1827 3.8173C16.6058 4.24037 16.6058 4.9263 16.1827 5.34937L17.5969 6.76358C18.801 5.55946 18.801 3.6072 17.5969 2.40309L16.1827 3.8173ZM16.1827 5.34937L4.70962 16.8225L6.12383 18.2367L17.5969 6.76358L16.1827 5.34937ZM5.41673 16.5296H2.50006V18.5296H5.41673V16.5296ZM13.2364 2.40309L1.79295 13.8466L3.20717 15.2608L14.6507 3.8173L13.2364 2.40309ZM1.50006 14.5537V17.5296H3.50006V14.5537H1.50006ZM11.9864 5.0673L14.9327 8.01358L16.3469 6.59937L13.4007 3.65309L11.9864 5.0673Z"
                            :fill="selectedSlides.length === 1 ? '#6B7280' : '#d1d5db'"
                        />
                    </svg>
                </button>
                <button @click="$emit('rotate-selected-slides')" class="icon-button">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.96,8.49c-.15-.15-.15-.41,0-.56l1.12-1.12c.15-.15.41-.15.56,0l2.3,2.3c.28-1.77-.27-3.65-1.64-5.01-2.27-2.27-5.95-2.27-8.22,0s-2.27,5.95,0,8.22c1.04,1.04,2.39,1.61,3.75,1.69.21.01.37.18.37.39v1.59c0,.22-.19.41-.42.39-1.96-.1-3.89-.89-5.39-2.39-3.2-3.2-3.2-8.37-.01-11.58,3.19-3.2,8.34-3.23,11.56-.05,1.74,1.71,2.55,4.01,2.43,6.27l1.82-1.82c.15-.15.41-.15.56,0l1.12,1.12c.15.15.15.41,0,.56l-4.68,4.68c-.15.15-.41.15-.56,0l-4.68-4.68h0Z"
                            :fill="isAnyImageSelected ? '#6B7280' : '#d1d5db'"
                            stroke-width="2"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>
                <button
                    @click="$emit('open-delete-modal')"
                    class="highlighted-icon-button"
                    style="border-top-right-radius: 6px; border-bottom-right-radius: 6px"
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M15.8333 5.83333L15.1105 15.9521C15.0482 16.8243 14.3225 17.5 13.4481 17.5H6.55184C5.67745 17.5 4.95171 16.8243 4.88941 15.9521L4.16665 5.83333M8.33331 9.16667V14.1667M11.6666 9.16667V14.1667M12.5 5.83333V3.33333C12.5 2.8731 12.1269 2.5 11.6666 2.5H8.33331C7.87308 2.5 7.49998 2.8731 7.49998 3.33333V5.83333M3.33331 5.83333H16.6666"
                            :stroke="isAnyImageSelected ? '#EF4444' : '#FCA5A5'"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import SlideUpload from '@/components/Tribute/SlideUpload.vue';

export default {
    data() {
        return {
            allowVideoUploads: true,
        };
    },
    components: {
        SlideUpload,
    },
    props: {
        maxFilesLeft: {
            type: Number,
            default: 0,
        },
        isExpanded: {
            type: Boolean,
            default: false,
        },
        selectedSlides: {
            type: Array,
            default: () => [],
        },
        disableMoveBack: {
            type: Boolean,
            default: true,
        },
        disableMoveForward: {
            type: Boolean,
            default: true,
        },
        dropTarget: {
            type: String,
            required: false,
        },
    },
    computed: {
        isAnyImageSelected() {
            return this.selectedSlides.length > 0;
        },
    },
    methods: {
        ...mapActions('tributeEditor', ['toggleExpandedState']),
        toggleHeight() {
            this.toggleExpandedState();
        },
        downloadSelectedSlides() {
            console.log('TODO: download slides');
        },

        initUpload(eventId) {
            if (!this.$refs.uploaderBtn) {
                throw new Error('Uploader not found');
            }

            return this.$refs.uploaderBtn.initUpload(eventId);
        },
    },
};
</script>
<style lang="scss" scoped>
.action-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
}

.left-buttons {
    display: flex;
    align-items: center;
    gap: 8px;
}

.right-buttons {
    display: flex;
    justify-content: space-between;
    border-radius: 6px;
    box-shadow: 0px 1px 2px 0px #0000000d;
}

.icon-button {
    width: 38px;
    height: 38px;
    background: white;
    border: 1px solid #d1d5db;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-button:hover {
    background-color: $light-gray;
}

.highlighted-icon-button {
    width: 38px;
    height: 38px;
    background: #fee2e2;
    border: 1px solid #fca5a5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.highlighted-icon-button:hover {
    background-color: #fca5a568;
}

.button-divider {
    width: 1px;
    height: 60%;
    background: #d1d5db;
    margin-right: 8px;
}

.icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.primary-button,
.secondary-button {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border-radius: 6px;
    padding: 10px 12px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.primary-button {
    background: #ea580c;
    color: white;
    border: none;
    &:hover {
        background-color: $btn-orange-hover;
    }
}

.secondary-button {
    background: white;
    color: #374151;
    border: 1px solid #d1d5db;
    &:hover {
        background-color: $light-gray;
    }
}

.shadow-button {
    box-shadow: 0px 1px 2px 0px #0000000d;
}
</style>
