<template>
    <div class="d-flex flex-column">
        <div class="action-bar mb-2">
            <div class="left-buttons"></div>
            <div class="right-buttons">
                <transition name="slide-in" v-if="tributeRender.standardRender">
                    <v-btn
                        :loading="downloading"
                        @click="downloadTributeVideo"
                        :elevation="0"
                        depressed
                        outlined
                        class="white-action-btn text-normal"
                    >
                        <font-awesome-icon icon="fa-solid fa-download" class="mr-2" /> Download
                    </v-btn>
                </transition>
                <transition name="slide-in" v-if="tributeRender.standardRender">
                    <button
                        :disabled="!tributeEmbedCode"
                        @click="showEmbedModal = true"
                        class="white-action-btn"
                        key="embed-btn"
                    >
                        <font-awesome-icon icon="fa-solid fa-code-simple" class="mr-2" /> Embed Code
                    </button>
                </transition>
                <!-- <button class="white-action-btn" @click="fakeFinishRender">
                    Fake Finish Render
                </button> -->
                <button
                    class="orange-action-btn"
                    @click="confirmRenderIfNeeded"
                    :disabled="!minimumCreateRequirementsMet || tributeIsRendering"
                >
                    Create Video
                </button>
            </div>
        </div>
        <div class="slides-container">
            <div :class="['slide photos', { incomplete: selectedPhotoCount === 0 }]">
                <div class="slide-top">
                    <div class="slide-icon" v-if="selectedPhotoCount === 0">
                        <img :src="photoIcon" />
                    </div>
                    <div class="thumb-stack" v-else>
                        <template v-for="photo in topThreeSelectedPhotos">
                            <ImageThumbnail
                                :key="photo.url"
                                :src="photo.url"
                                :w="null"
                                :h="60"
                                :type="photo.mediaType"
                                class="stack-image"
                            />
                        </template>
                    </div>
                </div>
                <div class="slide-bottom">
                    <div class="left">
                        <font-awesome-icon icon="fa-solid fa-check" v-if="selectedPhotoCount > 0" />
                        <font-awesome-icon icon="fa-regular fa-info-circle" v-else />
                    </div>
                    <div class="right">
                        <strong>Slides</strong>
                        <p class="p-0">You've uploaded {{ selectedPhotoCount }} photos.</p>
                    </div>
                </div>
            </div>
            <div :class="['slide theme', { incomplete: selectedThemeCount === 0 }]">
                <div class="slide-top">
                    <div class="slide-icon" v-if="selectedThemeCount === 0 || !selectedTheme">
                        <img :src="exploreIcon" alt="" />
                    </div>
                    <div class="thumb-stack singular" v-else>
                        <ImageThumbnail :src="selectedTheme.url" :w="60" :h="60" class="stack-image" :type="1" />
                    </div>
                </div>
                <div class="slide-bottom">
                    <div class="left">
                        <font-awesome-icon icon="fa-solid fa-check" v-if="selectedThemeCount > 0" />
                        <font-awesome-icon icon="fa-regular fa-info-circle" v-else />
                    </div>
                    <div class="right">
                        <strong>Set Theme</strong>
                        <p class="p-0">{{ selectedThemeCount > 0 ? 'Theme is set.' : 'Theme is not set.' }}</p>
                    </div>
                </div>
            </div>
            <div :class="['slide songs', { incomplete: selectedSongCount === 0 }]">
                <div class="slide-top">
                    <div class="slide-icon" v-if="selectedSongCount === 0">
                        <img :src="musicIcon" alt="" />
                    </div>
                    <div class="thumb-stack" v-else>
                        <template v-for="song in topThreeSelectedSongs">
                            <!-- <pre>{{ song }}</pre> -->
                            <ImageThumbnail
                                :key="song.id"
                                :src="song.artwork || fallbackArtwork"
                                :w="null"
                                :h="60"
                                class="stack-image"
                            />
                        </template>
                    </div>
                </div>
                <div class="slide-bottom">
                    <div class="left">
                        <font-awesome-icon icon="fa-solid fa-check" v-if="selectedSongCount > 0" />
                        <font-awesome-icon icon="fa-regular fa-info-circle" v-else />
                    </div>
                    <div class="right">
                        <strong>Add Music</strong>
                        <p class="p-0">You've added {{ selectedSongCount }} tracks.</p>
                    </div>
                </div>
            </div>
        </div>
        <v-dialog v-model="showConfirmRenderModal" :overlay-opacity="0.85" max-width="500">
            <template v-slot:default="{ isActive }">
                <v-card>
                    <v-card-text class="p-4">
                        <h4>Regenerate Tribute Video</h4>
                        <p>
                            You've made changes to your tribute video. To reflect these changes, the video needs to be
                            regenerated. A small fee will be charged. This will take a few minutes to complete.
                        </p>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="cancel" @click="() => (showConfirmRenderModal = false)">Cancel</v-btn>
                        <v-btn
                            color="primary"
                            @click="
                                () => {
                                    submitTributeRender();
                                    showConfirmRenderModal = false;
                                }
                            "
                            >Proceed to Regenerate</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>

        <v-dialog v-model="showEmbedModal" :overlay-opacity="0.85" max-width="500">
            <v-card>
                <v-card-text class="p-4">
                    <h4>Embed Code</h4>
                    <code> {{ tributeEmbedCode }} </code>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="cancel" @click="() => (showEmbedModal = false)">Close</v-btn>
                    <v-btn color="primary" @click="copyToClipboard(tributeEmbedCode)">
                        <font-awesome-icon class="mr-2" icon="fa-regular fa-clone"></font-awesome-icon>
                        Copy</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import TributeVideoService from '@/services/tributeVideo.service';
import ServicesService from '@/services/services.service';
import ImageThumbnail from '../Media/ImageThumbnail.vue';
import moment from 'moment';
export default {
    props: {
        tributeVideo: {
            type: Object,
            default: () => {},
        },
    },
    inject: ['tributeRender'],
    components: {
        ImageThumbnail,
    },
    computed: {
        isExpanded: {
            get() {
                return this.$store.state.tributeEditor.expanded;
            },
        },
        ...mapGetters('tributeVideo', [
            'minimumCreateRequirementsMet',
            'selectedPhotoCount',
            'topThreeSelectedPhotos',
            'topThreeSelectedSongs',
            'selectedSongCount',
            'selectedThemeCount',
            'selectedTheme',
            'tributeIsRendering',
        ]),
    },
    data() {
        return {
            tributeApi: null,
            serviceApi: null,
            axiosInstance: null,
            loading: false,
            renderInProg: false,
            exploreIcon: require(`@/assets/icons/explore.svg`),
            musicIcon: require(`@/assets/icons/music.svg`),
            photoIcon: require(`@/assets/icons/photo.svg`),
            fallbackArtwork: require('@/assets/images/fallback-song-art.png'),
            enableDownloadButton: false,
            enableEmbedButton: false,
            downloadCancelToken: null,
            showConfirmRenderModal: false,
            downloading: false,
            downloadProgress: 0,
            showEmbedModal: false,
            tributeEmbedCode: null,
            pendingRender: null,
            pendingRenderResolver: null
        };
    },
    methods: {
        copyToClipboard(val) {
            navigator.clipboard.writeText(val);
            this.showSnackbar({ message: 'Copied to clipboard' });
        },
        async fetchEmbedCode() {
            if (!this.tributeVideo.serviceId) return;

            const resp = await this.serviceApi.getEmbedCode(this.tributeVideo.serviceId);

            if (!resp.data.htmlWrapper) return;

            var tributeEmbedURL = `${process.env.VUE_APP_VIDEO}/video/${this.$route.params.slug}/tribute-video`;
            var htmlWrapper = resp.data.htmlWrapper;

            this.tributeEmbedCode = this.replaceEmbedCodePlaceholders(htmlWrapper, tributeEmbedURL);
        },
        replaceEmbedCodePlaceholders(embedCode, srcURL) {
            const links = {
                '{{src}}': srcURL,
                '{{tributeContributeLink}}': this.tributeVideo.deeplink || '',
                '{{storyContributeLink}}': this.tributeVideo.storyContributeLink || '',
                '{{storyViewLink}}': `${process.env.VUE_APP_BASE_URI}/view-story/${this.$route.params.slug}`,
                '{{tributeViewLink}}': srcURL.endsWith('/tribute-video') ? srcURL : `${srcURL}/tribute-video`,
            };

            for (const placeholder in links) {
                embedCode = this.replacePlaceholder(embedCode, links[placeholder], placeholder);
            }

            return embedCode;
        },
        replacePlaceholder(wrapper, url, placeHolder) {
            if (!wrapper) return url;
            return wrapper.replace(placeHolder, url);
        },
        cancelDownload() {
            this.downloadCancelToken.cancel('Download canceled by user.');
        },
        downloadTributeVideo() {
            if (!this.tributeRender?.standardRender?.url) {
                return;
            }
            let name = `${this.tributeVideo.firstName} ${this.tributeVideo.lastName}'s' Tribute`;
            this.showSnackbar({ message: 'Downloading' });
            this.downloadCancelToken = this.axios.CancelToken.source();

            this.downloading = true;
            this.axios
                .get(this.tributeRender?.standardRender?.url, {
                    responseType: 'blob',
                    onDownloadProgress: progressEvent => {
                        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        this.downloadProgress = progress;
                    },
                    cancelToken: this.downloadCancelToken.token,
                })
                .then(response => {
                    const blob = new Blob([response.data], { type: 'video/mp4' });
                    const blobUrl = window.URL.createObjectURL(blob);
                    const anchor = document.createElement('a');
                    anchor.style.display = 'none';
                    anchor.href = blobUrl;
                    anchor.download = name;
                    document.body.appendChild(anchor);
                    anchor.click();
                    window.URL.revokeObjectURL(blobUrl);
                    this.showSnackbar({ message: 'Download complete' });
                })
                .catch(error => {
                    console.log(error);
                    if (this.axios.isCancel(error)) {
                        this.showSnackbar({ message: 'Download Canceled', color: 'error' });
                    } else {
                        this.showSnackbar({ message: 'Error downloading video', color: 'error' });
                    }
                })
                .finally(() => {
                    this.downloading = false;
                    this.downloadCancelToken = null;
                    this.downloadProgress = 0;
                });
        },
        ...mapActions(['showSnackbar', 'block']),
        ...mapActions('tributeEditor', ['toggleExpandedState']),
        toggleHeight() {
            this.toggleExpandedState();
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        fakeFinishRender() {
            this.$store.dispatch('tributeVideo/updateTributeVideo', { renderCompleted: new Date().toISOString() });
        },
        confirmRenderIfNeeded() {
            // if there are no unsynced changes then there's no need to render again..
            // Let the user know
            if (this.tributeRender?.standardRender?.url) {
                this.showConfirmRenderModal = true;
            } else {
                this.submitTributeRender();
            }
        },
        async submitTributeRender() {
            // For testing locally without triggering an actual render
            // this.$store.dispatch('tributeVideo/updateTributeVideo', { renderStarted: moment().toISOString(), renderCompleted: null, overlayDismissed: false });
            // this.$emit('render-started');
            // return;
            try {
                if (!this.tributeVideo.id) {
                    this.showSnackbar({ message: 'Error submitting render', color: 'error' });
                    return;
                }
                // Starts the render loading UI
                this.$store.dispatch('tributeVideo/updateTributeVideo', {
                    renderStarted: moment().toISOString(),
                    // Reset any previous render stats/state
                    renderCompleted: null,
                    overlayDismissed: false
                });
                // delayRenderCallByMilliseconds
                // console.info('Pre Render stats...');
                // console.info(':: Tribute Video Duration:', this.$store.state.tributeVideo.totalDuration);
                this.loading = true;
                // this.block(true);
                this.pendingRender = new Promise((resolve) => {
                    const renderDelay = this.$store.getters['tributeVideo/delayRenderCallByMilliseconds'];
                    console.log('Delaying render by this much:', renderDelay);
                    let resolveTimeout = setTimeout(() => {
                        console.log('Resolving render promise with timeout');
                        this.pendingRenderResolver = null;
                        resolve();
                    }, renderDelay);
                    this.pendingRenderResolver = function () {
                        // Clear timeout since we're resolving it now instead
                        if (resolveTimeout) {
                            clearTimeout(resolveTimeout);
                        }
                        resolve();
                        this.pendingRenderResolver = null;
                    }
                }).then(() => {
                    console.log('Render Promise resolved... kicking off delayed render to api');
                    this.tributeApi.submitRender(this.tributeVideo.id);
                })

                this.showSnackbar({
                    message: 'Tribute Video Uploaded. Please allow time for processing',
                });
                // TODO: Fetch render status on page mount
                this.renderInProg = true;
                this.$emit('render-started');
            } catch (error) {
                const errorMessage = error.response.data.Message;
                // we errored, so get rid of the rendering overlay
                this.$store.dispatch('tributeVideo/updateTributeVideo', {
                    renderStarted: null,
                    overlayDismissed: true,
                    renderCompleted: null,
                });
                if (errorMessage == 'Multipart render error') {
                    this.showSnackbar({
                        color: 'error',
                        message: 'Multipart render error, please try another template.',
                    });
                } else {
                    this.showSnackbar({ color: 'error', message: 'Error creating tribute video' });
                }
            } finally {
                this.loading = false;
                // this.block(false);
            }
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.tributeApi = TributeVideoService(this.token);
        this.serviceApi = ServicesService(this.token);
        this.createAxiosInstance(this.token);

        this.fetchEmbedCode();
        // Setup beforeunload 
        addEventListener("beforeunload", (event) => {
            if (this.pendingRenderResolver) {
                // prevent user leaving until we're sure the api call has been kicked off
                event.preventDefault();
                // Included for legacy support, e.g. Chrome/Edge < 119
                event.returnValue = true;
                this.pendingRenderResolver();
                // console.info('Kicking off delayed render!! triggered by [beforeunload]');
            }
        });
    },
    beforeDestroy() {
        if (this.pendingRenderResolver) {
            // console.info('Kicking off delayed render!! triggered by [beforeDestroy]');
            this.pendingRenderResolver();
        }
    },
    beforeRouteLeave(_to, _from, next) {
        // console.log('beforeRouteLeave')
        if (this.pendingRenderResolver) {
            // console.info('Kicking off delayed render, triggered by [beforeRouteLeave]');
            this.pendingRenderResolver();
            return next();
        }
        next();
    }
};
</script>
<style lang="scss" scoped>
.primary-button,
.secondary-button {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.primary-button {
    width: 228px;
    background: #ea580c;
    color: white;
    border: none;
    height: 38px;
    border-radius: 6px;
    margin-right: 8px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.secondary-button {
    width: 138px;
    background: white;
    color: #374151;
    border: 1px solid #d1d5db;
    height: 38px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.primary-button:disabled {
    opacity: 0.5;
}

.action-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 38px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.right-buttons {
    display: flex;
    align-items: center;
}

.icon-button {
    margin-right: 8px;
    width: 38px;
    height: 38px;
    background: white;
    border: 1px solid #d1d5db;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}
.slides-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
    overflow-x: auto;
    overflow-y: hidden;
    transition: height 0.8s;
    height: 100%;
}

.slide {
    height: 100%;
    max-height: 242px;
    min-height: 100px;
    aspect-ratio: 1;
    border-radius: 8px;
    position: relative;
    background: $light-gray;
    // transition: transform 0.2s, border 0.2s;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .slide-bottom {
        display: flex;
        width: 100%;
        align-self: flex-end;
        .left {
            flex: 0 1;
            min-width: 20%;
            text-align: center;
            svg {
                height: 1.4em;
            }
            color: $medium-green;
        }
        .right {
            flex: 1 0;
            strong {
                margin-bottom: 10px;
            }
            p {
                font-size: 0.9rem;
                color: $primary-grey;
            }
        }
    }
    .slide-icon {
        border-radius: 100%;
        width: min(50px, 25%);
        aspect-ratio: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        inset: 10px;
        background: lighten($medium-green, 50%);
        color: $medium-green;
        img {
            width: 40%;
            filter: brightness(1.1) hue-rotate(292deg) contrast(1) saturate(15);
            user-select: none;
        }
    }
    &.incomplete {
        .left {
            color: $medium-red;
        }
        .slide-icon {
            background: lighten($medium-gray, 25%);
            color: $medium-gray;

            img {
                width: 40%;
                filter: brightness(1.5) hue-rotate(238deg) contrast(0.1) saturate(0);
            }
        }
    }
}
.thumb-stack {
    .stack-image {
        position: absolute;
        border-radius: 10px;
        filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.5));
        height: 60px;
        aspect-ratio: 1;
        width: 60px;
    }
    &:not(.singular) {
        .stack-image {
            transition: all 160ms ease-in-out;

            &:nth-child(1) {
                transform: translate(39px, 18px) rotate(17deg);
                z-index: 3;
            }
            &:nth-child(2) {
                transform: translate(22px, 35px);
                z-index: 2;
            }
            &:nth-child(3) {
                transform: translate(56px, 38px) rotate(-15deg);
                z-index: 1;
            }
        }
    }
    &.singular {
        .stack-image {
            transform: translate(19px, 27px);
        }
    }
}
.slide:hover {
    .thumb-stack:not(.singular) .stack-image {
        &:nth-child(1) {
            transform: translate(53px, 18px) rotate(2deg);
            z-index: 3;
        }

        &:nth-child(2) {
            transform: translate(15px, 35px);
            z-index: 2;
        }

        &:nth-child(3) {
            transform: translate(60px, 55px) rotate(-2deg);
            z-index: 1;
        }
    }
}
.white-action-btn {
    @include tribute-btn(white);
}
.orange-action-btn {
    @include tribute-btn($btn-orange, white, $btn-orange);
}
::v-deep .right-buttons {
    width: auto;
    gap: 8px;
    box-shadow: none;
}

.slide-in-enter-active {
    transition: all 0.3s ease;
}

.slide-in-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-in-enter,
.slide-in-leave-to {
    transform: translateY(20px);
    opacity: 0;
}
.slide-in-leave,
.slide-in-enter-to {
    transform: translateY(0);
    opacity: 1;
}
.v-btn.cancel {
    &::v-deep {
        background: white !important;
        color: $medium-gray;
        text-transform: none;
        letter-spacing: normal;
        outline: 1px solid $medium-gray;
    }
}
.v-btn.primary {
    &::v-deep {
        background: $primary-orange !important;
        color: white;
        text-transform: none;
        letter-spacing: normal;
    }
}
</style>
