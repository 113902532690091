<template>
    <div :class="['final-step-overlay', miniMode ? 'mini': '', confetti ? 'confetti' : '']">
        <div class="overlay-content">
            <div class="overlay-message" @click="expandIfMini">
                <!-- <lottie :options="loaderDefaultOptions" :height="150" v-on:animCreated="handleAnimation" /> -->
                <img src="/logo-animated.gif" class="ms-loading-logo">

                <div class="progress-indicator">
                    <div class="progress-slider" :style="{ width: `${renderProgress}%` }"></div>
                </div>
                <p class="text-title">({{ renderProgress }}%) <span class="render-text">{{ renderProgress < 100
                            ? 'Rendering your tribute video...' : 'Render finished!' }}</span>
                </p>
                <p class="progress-messages">{{ renderProgress < 100 ? renderMessages[currentMessageIndex] : miniMode
                        ? 'Render complete.' : '&nbsp;' }}</p>
                    <!-- <p>Est. render time: {{ willFinishOn }}</p> -->
                    <div class="preview-toggle">
                        <button class="play-btn" @click.stop="toggleOverlay"
                            v-if="!miniMode && renderProgress < 100">
                            <font-awesome-icon icon="fa-solid fa-play" class="" /> <span
                                class="hidden-while-mini">Watch preview</span>
                        </button>
                        <div v-else-if="!miniMode && renderProgress >= 100" class="text-center">
                            <button class="play-btn orange-btn" @click.stop="watchFinalVideo">
                                <font-awesome-icon icon="fa-solid fa-play" class="px-1" /> Watch Final Tribute Video
                            </button>
                        </div>
                        <button class="play-btn" @click.stop="toggleOverlay" v-else>
                            <font-awesome-icon icon="fa-solid fa-chevron-down" class="px-1 fa-lg" />
                        </button>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Lottie from 'vue-lottie';
// import loadingAnimation from './lottie-files/logo.json';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
const renderMessaging = [
    'Gathering your memories.',
    'Matching music to the moments.',
    'Adding a touch of magic.',
    'Turning photos into stories.',
    'Creating a timeless tribute.',
    'Perfecting the transitions.',
    'Synchronizing music and slides.',
    'Almost done crafting your masterpiece.',
    'Bringing your cherished moments to life.',
    'Making it picture-perfect.'
]
export default {
    name: 'TributeRenderOverlay',
    components: { },

    data() {
        return {
            renderStarted: null,
            miniMode: false,
            currentMessageIndex: 0,
            renderMessages: renderMessaging,
            renderProgress: 0,
            // loaderDefaultOptions: { animationData: loadingAnimation },
            messageInterval: null,
            progressInterval: null,
            autoPlay: true,
            autoPause: true,
            confetti: false,
        }
    },
    computed: {

        // duration is estimated at 2 X tribute video duration
        renderDuration() {
            return this.$store.state.tributeVideo.totalDuration * 2;
        },
        estRenderFinish() {
            if (!this.renderStarted) { 
                return null;
            }
            // return a moment object that is renderStart + renderDuration
            return moment(this.renderStarted).add(this.renderDuration, 'seconds');
        },
        willFinishOn() {
            if (this.estRenderFinish) {
                return this.estRenderFinish.fromNow(true);
            }
            return null;
        }
    },
    methods: {
        startConfetti() {
            this.confetti = true;
            setTimeout(() => {
                this.confetti = false;
            }, 2140);
        },
        expandIfMini() {
            if (this.miniMode) {
                this.toggleOverlay();
            }
        },
        toggleOverlay() {
            // Toggle miniMode;
            this.miniMode = !this.miniMode;

            // If overlay is in expanded mode:
            // Auto-play the preview ?
            if (this.miniMode && this.autoPlay) {
                this.$emit('playPreview');
            }
            // else if overlay is in mini mode
            // Auto-pause the preview ?
            if (!this.miniMode && this.autoPause) {
                this.$emit('pausePreview');
            }
        },
        start() {
            this.clearIntervals();
            this.progressInterval = setInterval(() => {
                this.calculatePercentage();
            }, 100);
            this.messageInterval = setInterval(() => {
                this.currentMessageIndex++;
                if (this.currentMessageIndex > (this.renderMessages.length - 1)) {
                    this.currentMessageIndex = 0;
                }
            }, 2500);
        },
        forceCompleteRender() {
            // stop Intervals
            this.clearIntervals();
            this.renderProgress = 100;
            this.startConfetti();
        },
        handleAnimation: function (anim) {
            this.anim = anim;
        },
        calculatePercentage() {
            let progress = this.renderProgress;
            // if render is finished we can fastpass straight to 100
            if (this.$store.state.tributeVideo.renderCompleted) {
                progress = 100;
            } else {
                const today = moment();
                const secondsFromStart = today.diff(this.renderStarted, 'seconds'); // Seconds since start date
                progress = (secondsFromStart / this.renderDuration) * 100; // Progress percentage
            }
            
            if (!progress || progress <= 0) {
                this.renderProgress = 0;
            }else if (progress >= 100) {
                this.renderProgress = 100;
                // Do we emit an event when our render time has completed?
                this.$root.$emit('tribute:renderComplete');
                this.clearIntervals();
                this.expandIfMini();
                this.$nextTick(() => {
                    this.startConfetti();
                });
            } else {
                this.renderProgress = Math.round(progress);
            }
        },
        clearIntervals() {
            if (this.messageInterval) {
                this.currentMessageIndex = 0;
                clearInterval(this.messageInterval);
            }
            if (this.progressInterval) {
                clearInterval(this.progressInterval);
            }
        },
        watchFinalVideo() {
            // dismiss the overlay by clearing the render info
            this.$store.dispatch('tributeVideo/dismissRenderOverlay');
            // this.$store.dispatch('tributeVideo/updateTributeVideo', {
                
            // });
        }
    },
    mounted() {
        this.renderStarted = this.$store.state.tributeVideo.renderStarted;
        this.start();
    },
    beforeDestroy() {
        // remove Intervals
        this.clearIntervals();
    },
}
</script>

<style lang="scss" scoped>
.final-step-overlay {
    position: absolute;
    display: flex;
    inset: 14px;
    align-items: center;
    z-index: 10;
    color: white;
    transition: all 300ms ease-in-out;
    &::after {
        content: '';
        position: absolute;
        pointer-events: none;
        inset: 0;
        background: rgba(6, 6, 6, 0.697);
        backdrop-filter: blur(4px);
        filter: blur(1px);
        pointer-events: none;
        margin-bottom: 6px;
    }
    .overlay-content {
        color: white;
        position: relative;
        z-index: 2;
        padding: 16%;
        text-align: center;
        font-size: 0.85rem;
        width: 100%;

        .overlay-message {
            width: min(90%, 440px);
            margin: auto;
        }
    }
    &.mini {
        height: 40px;
        transform: scale(0.85);
        cursor: pointer;
        &:hover {
            transform: scale(0.9);
        }
        .hidden-while-mini {
            display: none;
        }
        &:after {
            display: none;
        }
        .overlay-content {
            padding: 0 10px;
            width: 70%;
            margin: auto;
            background: rgba(0, 0, 0, 0.7);
            border-radius: 12rem;
            top: 10px;
        }
        .overlay-message {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            gap: 10px;
            height: 45px;
            .render-text {
                display: none;
            }
            .ms-loading-logo {
                margin: auto;
                max-height: 60px;
                position: relative;
                top: -18%;
            }
            .progress-indicator {
                margin: auto;
                max-width: 20%;
            }
            p {
                margin: 0;
            }
            .progress-messages {
                flex-grow: 1;
                text-align: left;
            }
        }
    }
    &.confetti {
        &:before {
            content: '';
            background: url('/confetti.gif') no-repeat bottom center;
            background-size: cover;
            inset: 0;
            margin: auto;
            position: absolute;
            z-index: 9;
        }
    }
}
.text-title {
    font-size: 16px;
    font-weight: bold;
}
.progress-indicator {
    width: 50%;
    display: inline-block;
    height: 5px;
    background: #ffedd5;
    margin: auto;
    border-radius: 10rem;
    margin-bottom: 10px;
}

.progress-slider {
    height: 100%;
    background: #f97316;
    border-radius: 10rem;
    transition: width 200ms ease-in-out;
}
.ms-loading-logo {
    max-width: min(150px, 37%);
    display: block;
    margin: auto;
    margin-bottom: -12px;
}
.play-btn {
    font-weight: bold;
}
.orange-btn {
    @include icon-btn($primary-orange, white, $btn-orange);
    &::v-deep {
        width: 60%;
        margin: auto;
    }
}
</style>