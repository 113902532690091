<template>
    <div style="z-index: 11">
        <v-menu offset-y v-model="menuOpen" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
                <button class="share-button" v-bind="attrs" v-on="on" :disabled="disabled">
                    <span class="hidden-lg-and-up">Share</span>
                    <span class="hidden-md-and-down">Share Link for Family</span>
                    <span class="divider"></span>
                    <span class="chevron">&#9662;</span>
                </button>
            </template>
            <v-card>
                <v-list class="sharing-menu">
                    <!-- Family Share -->
                    <v-list-item>
                        <v-list-item-title> Invite Family to Upload Media </v-list-item-title>
                        <v-list-item-subtitle>
                            Allow family members to contribute photos and videos for the tribute.
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                            <v-text-field
                                outlined
                                dense
                                class="has-addon__right"
                                prepend-inner-icon="mdi-account-multiple-outline"
                                :value="familyShareLink"
                                readonly
                            >
                                <template slot="preppend">
                                    <i class="fa fa-user-friends"></i>
                                </template>
                                <v-btn
                                    :disabled="familyShareLink === '...'"
                                    depressed
                                    slot="append-outer"
                                    @click="copyToClipboard('Family share', familyShareLink)"
                                >
                                    <v-icon left dark class="mr-1"> mdi-clipboard-outline </v-icon>
                                    Copy</v-btn
                                >
                            </v-text-field>
                        </v-list-item-subtitle>
                    </v-list-item>
                    <!-- Contributors -->
                    <v-list-item>
                        <v-list-item-title> Contributors </v-list-item-title>
                        <v-list-item-subtitle>
                            Only allows uploading photos.<br />
                            Recommended for extended family and friends.
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                            <v-text-field
                                outlined
                                dense
                                class="has-addon__right"
                                prepend-inner-icon="mdi-account-group-outline"
                                :value="contributorLink"
                                readonly
                            >
                                <v-btn
                                    :disabled="contributorLink === '...'"
                                    depressed
                                    slot="append-outer"
                                    @click="copyToClipboard('Contributor', contributorLink)"
                                >
                                    <v-icon left dark class="mr-1"> mdi-clipboard-outline </v-icon>
                                    Copy</v-btn
                                >
                            </v-text-field>
                        </v-list-item-subtitle>
                    </v-list-item>
                    <hr class="m-2" />
                    <!-- Share Video -->
                    <v-list-item>
                        <v-list-item-title> Share Completed Tribute Video </v-list-item-title>
                        <v-list-item-subtitle>
                            Allow anyone with the link to view the tribute video.
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                            <v-text-field
                                outlined
                                dense
                                class="has-addon__right"
                                prepend-inner-icon="mdi-play-circle-outline"
                                :value="completedVideoLink"
                                readonly
                            >
                                <v-btn
                                    :disabled="completedVideoLink === '...'"
                                    depressed
                                    slot="append-outer"
                                    @click="copyToClipboard('Completed video', completedVideoLink)"
                                >
                                    <v-icon left dark class="mr-1"> mdi-clipboard-outline </v-icon>
                                    Copy</v-btn
                                >
                            </v-text-field>
                        </v-list-item-subtitle>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-menu>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            menuOpen: false,
        };
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        familyShareLink() {
            return this.$store.state.tributeVideo.familyLink || '...';
        },
        contributorLink() {
            return this.$store.state.tributeVideo.deeplink || '...';
        },
        completedVideoLink() {
            // TODO: Figure out where the completed video link lives
            if (this.tributeRender?.standardRender?.url) {
                return `${process.env.VUE_APP_VIDEO}/video/${this.$route.params.slug}/tribute-video`;
            }

            return '...';
        },
    },
    inject: ['tributeRender'],
    methods: {
        ...mapActions(['showSnackbar']),
        copyToClipboard(type, link) {
            // TODO: browser support for this is really good, but need to double check if a fallback is needed
            navigator.clipboard.writeText(link);
            this.showSnackbar({
                message: `${type} link copied.`,
            });
            this.sharingMenuOpen = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.share-button {
    padding: 0.5rem 1rem;
    font-weight: 500;
    background-color: $primary-orange;
    color: white;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    gap: 8px;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
}

.sharing-menu {
    padding: 1.5rem 0.5rem;
    display: flex;
    flex-direction: column;

    .v-list-item {
        display: inline-block;
        padding: 0.5rem;
    }

    .v-list-item__title {
        font-weight: 500;
    }

    .v-list-item__title,
    .v-list-item__subtitle {
        margin: 4px 0;
        line-height: 1.3;
    }

    ::v-deep {
        .v-input.has-addon__right {
            .v-input__slot {
                margin: 0;
            }

            .v-input__control {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                fieldset {
                    border-color: #ccc;
                    border-right: transparent;
                }
            }

            .v-input__append-outer {
                margin: 0 !important;

                button {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    border: 1px solid #ccc;
                    height: 40px;
                }
            }
        }
    }
}

.share-button .divider {
    width: 1px;
    height: 20px;
    background-color: white;
    margin-left: 8px;
}
</style>
