<template>
    <div class="pa-3">
        <v-card-title>
            <font-awesome-icon icon="fa-regular fa-database" style="margin-right: 0.5rem" />
            <h3 class="m-0">Redis</h3>
        </v-card-title>

        <div style="border: 1px solid #ff5252" class="d-flex justify-space-between align-end p-3 rounded-lg">
            <div>
                <strong>Dump Redis DB</strong>
                <p class="mb-0">This will clear the entire redis database.</p>
            </div>

            <v-btn depressed @click="confirmModal = true" color="error">Clear Full DB Cache</v-btn>
        </div>

        <v-dialog v-model="confirmModal" max-width="400px">
            <v-card>
                <v-card-title>Dump Redis DB</v-card-title>
                <v-card-text>This will clear the entire redis database. Confirm to continue.</v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn @click="confirmModal = false" depressed>Cancel</v-btn>
                    <v-btn @click="confirmDumpRedisDB" depressed color="error">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import AdminToolService from '@/services/adminTool.service';

export default {
    data() {
        return {
            token: null,
            apiInstance: null,
            confirmModal: false,
        };
    },
    methods: {
        ...mapActions(['showSnackbar']),
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        async confirmDumpRedisDB() {
            try {
                const res = await this.apiInstance.dumpRedisDB();

                this.confirmModal = false;
                this.showSnackbar({ message: 'Success' });
            } catch (error) {
                console.log(error, 'error');
                this.showSnackbar({ message: 'Error clearing redis cache', color: 'error' });
            }
        },
    },
    async mounted() {
        await this.setAuthToken();

        this.apiInstance = AdminToolService(this.token);

        console.log(this.apiInstance, 'api instance');
    },
};
</script>
<style lang=""></style>
