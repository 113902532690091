<template>
    <div class="step-setup">
        <div class="container">
            <div>
                <SlideUpload
                    class="mb-8"
                    ref="uploader"
                    :videoAllowed="false"
                    :maxFiles="1"
                    @filesAdded="file => onFileAdded(file)"
                >
                    <template #activator="{ onClick }">
                        <div class="d-flex flex-column align-center">
                            <div @click="onClick" class="profile-wrapper pointer">
                                <div class="pending-slide" v-if="pendingUpload">
                                    <ImagePreview :media="pendingUpload" alt="Profile Photo" />
                                    <div class="progress-indicator">
                                        <div
                                            class="progress-slider"
                                            :style="{ width: `${pendingUpload.progress.percentage}%` }"
                                        ></div>
                                    </div>
                                </div>

                                <div v-else-if="profileImageUrl" class="image-preview">
                                    <!-- class="slide-image" -->

                                    <ImageThumbnail
                                        class="image-thumbnail"
                                        :src="profileImageUrl"
                                        :type="0"
                                        :h="500"
                                        :w="500"
                                    />

                                    <button @click.stop="removeImage" class="delete-image">
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M15.833 5.833L15.111 15.952c-.062.872-.788 1.548-1.662 1.548H6.552c-.875 0-1.601-.676-1.663-1.548L4.167 5.833M8.333 9.167v5M11.667 9.167v5M12.5 5.833V3.333c0-.46-.373-.833-.833-.833H8.333c-.46 0-.833.373-.833.833v2.5M3.333 5.833h13.333"
                                                stroke="#EF4444"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    </button>
                                </div>

                                <div v-else class="profile-place-holder">
                                    <font-awesome-icon
                                        icon="fa-solid fa-user"
                                        style="font-size: 2.5rem"
                                    ></font-awesome-icon>
                                </div>
                            </div>
                        </div>
                    </template>
                </SlideUpload>

                <h5 class="font-weight-bold">Upload {{ firstName }}'s Image</h5>
                <p>Upload a photo of your loved one to serve as the thumbnail for this tribute.</p>
                <div class="d-flex" style="gap: 12px">
                    <button @click="openFileExplorer" for="image" class="secondary-button">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M2.667 10.667v.666c0 1.105.896 2 2 2h6.667c1.105 0 2-.895 2-2v-.666M10.667 5.333L8 2.667M8 2.667L5.333 5.333M8 2.667v8"
                                stroke="#6B7280"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <span> {{ profileImageUrl ? 'Re-Upload' : 'Upload' }}</span>
                    </button>

                    <button v-if="!pendingUpload" @click="removeImage" class="danger-button">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15.833 5.833L15.111 15.952c-.062.872-.788 1.548-1.662 1.548H6.552c-.875 0-1.601-.676-1.663-1.548L4.167 5.833M8.333 9.167v5M11.667 9.167v5M12.5 5.833V3.333c0-.46-.373-.833-.833-.833H8.333c-.46 0-.833.373-.833.833v2.5M3.333 5.833h13.333"
                                stroke="#EF4444"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </button>
                </div>
            </div>
            <div class="deadline-container mt-4">
                <h5 class="font-weight-bold">Set a Deadline for Contributions</h5>
                <p>Set a deadline for contributors to submit photos and videos for the tribute.</p>
                <div class="select-wrapper">
                    <calendar
                        datetime
                        v-model="deadline.date"
                        @save="updateDeadline"
                        top
                        label="Choose Date and Time"
                        outlined
                    />
                </div>
            </div>

            <button v-if="tributeVideoExists" :disabled="loading" @click="handleClickNext" class="primary-button">
                <span class="button-text">Next</span>
            </button>
            <button v-else :disabled="loading" @click="createTributeVideo" class="primary-button">
                <span class="button-text">Create Tribute Video</span>
            </button>
        </div>
    </div>
</template>

<script>
import initApiServices from '@/services/ApiInitializer';
import Calendar from '@/components/ui/Calendar.vue';
import SlideUpload from '@/components/Tribute/SlideUpload.vue';
import { mapActions } from 'vuex';
import ImageThumbnail from '../Media/ImageThumbnail.vue';
import ImagePreview from '../Media/ImagePreview.vue';

export default {
    name: 'TributeSetup',
    data() {
        return {
            deadline: {
                date: null,
                time: null,
            },
            image: {
                previewURL: null,
                file: null,
            },
            loading: false,
            token: null,
            apiService: null,
            pendingUpload: null,
            tempFiles: [],
        };
    },
    props: {
        serviceSlug: {
            type: String,
            required: true,
        },
        tributeVideo: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        calendar: Calendar,
        SlideUpload,
        ImageThumbnail,
        ImagePreview,
    },
    computed: {
        tributeVideoExists() {
            if (!this.tributeVideo || !this.tributeVideo.id) return false;

            return this.tributeVideo.id > 0;
        },

        firstName() {
            return this.tributeVideo?.firstName || 'Honoree';
        },
        profileImageUrl() {
            return this.tributeVideo?.mainPhotoUrl;
        },
    },

    methods: {
        ...mapActions(['showSnackbar', 'block']),
        openFileExplorer() {
            if (!this.$refs.uploader || typeof this.$refs.uploader.openFileSelection() !== 'function') {
                console.log('error opening file explorer');
                return;
            }

            this.$refs.uploader.openFileExplorer();
        },
        async onFileAdded(file) {
            if (this.tributeVideoExists && this.tributeVideo.eventId) {
                await this.handleUpdateProfileImage(file);
            } else {
                if (file) {
                    const reader = new FileReader();
                    reader.onload = e => {
                        this.$store.dispatch('tributeVideo/updateTributeVideo', {
                            ...this.tributeVideo,
                            mainPhotoUrl: e.target.result,
                        });
                    };

                    let fileData = file.data ? file.data : file;
                    reader.readAsDataURL(fileData);
                } else {
                    this.removeImage();
                }
            }
        },
        async handleUpdateProfileImage(file) {
            this.pendingUpload = file;
            const uploadResp = await this.uploadPhoto(this.tributeVideo.eventId, true);
            this.pendingUpload = null;

            if (uploadResp.data.length === 0) {
                this.showSnackbar({ message: 'Error updating profile', color: 'error' });
                return;
            }

            this.$store.dispatch('tributeVideo/updateTributeVideo', {
                ...this.tributeVideo,
                mainPhotoUrl: uploadResp.data[0].url,
                mainPhotoId: uploadResp.data[0].id,
            });

            this.showSnackbar({ message: 'Profile picture updated' });
        },
        uploadPhoto(eventId, replaceMainPhoto = false) {
            if (!eventId || eventId <= 0) {
                throw new Error('Invalid event id');
            }

            const uploader = this.$refs.uploader;

            if (!uploader || typeof uploader.initUpload !== 'function') {
                throw new Error('Error initiating upload');
            }

            return uploader.initUpload(eventId, replaceMainPhoto);
        },
        async removeImage() {
            // this.image = {
            //     previewURL: null,
            //     file: null,
            // };

            this.$store.dispatch('tributeVideo/updateTributeVideo', {
                ...this.tributeVideo,
                mainPhotoId: 0,
                mainPhotoUrl: null,
            });

            const uploader = this.$refs.uploader;
            if (uploader && typeof uploader.reset === 'function') {
                uploader.reset();
            }

            if (this.tributeVideo?.mainPhotoId) {
                await this.apiService.tributePhoto.deletePhoto(this.tributeVideo?.mainPhotoId);
                this.showSnackbar({ message: 'Profile picture deleted' });
            }
        },
        async updateDeadline() {
            if (this.deadline.date) {
                const data = {
                    deadline: this.deadline.date,
                };

                if (this.tributeVideo?.id) {
                    this.$store.dispatch('tributeVideo/updateTributeVideo', {
                        ...this.tributeVideo,
                        deadline: data.deadline,
                    });
                    await this.apiService.tributeVideo.updateDeadline(this.tributeVideo.id, data);
                    this.showSnackbar({ message: 'Deadline updated' });
                }
            }
        },
        handleClickNext() {
            this.$emit('next-step');
        },
        async createTributeVideo() {
            const slug = this.serviceSlug;
            if (!slug) {
                this.showSnackbar({ messaage: 'Invalid service slug', color: 'error' });
                return;
            }

            try {
                let tributeData = {
                    deadline: this.deadline.date,
                };

                this.loading = true;
                this.block(true);
                const tributeResp = await this.apiService.tributeVideo.createTributeVideoByService(slug, tributeData);

                if (!tributeResp.data.eventId) {
                    throw new Error('Error creating  tribute video');
                }

                const eventId = tributeResp.data.eventId;

                if (this.image.file) {
                    const photoResp = await this.uploadPhoto(eventId);
                }

                this.$emit('tribute-video-created', tributeResp.data);
            } catch (error) {
                console.log(error, 'error creating tribute video');
            } finally {
                this.loading = false;
                this.block(false);
            }
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.apiService = initApiServices(this.token);

        if (this.tributeVideo?.deadline) {
            const localDeadline = this.$moment.utc(this.tributeVideo.deadline).local();
            this.deadline.date = localDeadline.toISOString();
        }
    },
};
</script>

<style lang="scss" scoped>
.step-setup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.container {
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
}

.deadline-container,
.image-upload {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

// h2 {
//     color: #6b7280;
//     // font-weight: 600;
//     // font-size: 12px;
//     // line-height: 16px;
//     text-transform: uppercase;
// }

// p {
//     color: #111827;
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 20px;
// }

.select-wrapper {
    display: flex;
    gap: 8px;
    width: 100%;
}

.select-button {
    padding: 10px;
    width: 100%;
    background-color: #fff;
    color: #374151;
    cursor: pointer;
    font-weight: 500;
    border: 1px solid #d1d5db;
    box-shadow: 0px 1px 2px 0px #0000000d;
    border-radius: 6px;
}

.secondary-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #d1d5db;
    background-color: #fff;
    color: #374151;
    cursor: pointer;
    box-shadow: 0px 1px 2px 0px #0000000d;

    &:hover {
        background-color: $light-gray;
    }
}

.danger-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px;
    border-radius: 4px;
    background: #fee2e2;
    border: 1px solid #fca5a5;
    color: #374151;
    cursor: pointer;
    box-shadow: 0px 1px 2px 0px #0000000d;

    &:hover {
        background: #ffd3d3;
    }
}

#image {
    display: none;
}

// .delete-image {
//     // position: absolute;
//     // bottom: 5px;
//     // right: 5px;
//     background: #fee2e2;
//     border: 1px solid #fca5a5;
//     border-radius: 4px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     padding: 10px;
//     // width: 24px;
//     // height: 24px;
//     cursor: pointer;
// }

.primary-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    background-color: #ea580c;
    color: #fff;
    border: none;
    cursor: pointer;
    width: 100%;
    border-radius: 6px;
    transition: all 0.3s;
}

.primary-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pending-slide {
    height: 100%;
    width: 100%;

    img {
        opacity: 0.4;
        height: 100%;
        width: 100%;
        max-width: 300px;
        object-fit: cover;
    }

    .progress-indicator {
        width: 93%;
        display: inline-block;
        height: 5px;
        bottom: 10px;
        position: absolute;
        left: 0;
        right: 0;
        background: #ffedd5;
        margin: auto;
        border-radius: 10rem;
    }

    .progress-slider {
        height: 100%;
        background: #f97316;
        border-radius: 10rem;
        transition: width 200ms ease-in-out;
    }
}

.profile-wrapper {
    aspect-ratio: 1;
    border-radius: 10rem;
    width: 100%;
    max-width: 300px;
    overflow: hidden;
}

.image-preview {
    // position: relative;
    display: inline-block;
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
    aspect-ratio: 1;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.image-preview img {
    border-radius: 8px;
}

.profile-place-holder {
    background-color: #dbe2e8;
    height: 100%;
    width: 100%;
    flex-grow: 1;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;

    h1 {
        // color: white;
        margin: 0;
        padding: 0;
    }
}
</style>
