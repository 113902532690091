import TributeVideoService from './tributeVideo.service';
import TributeTemplateService from './tributeTemplate.service';
import TributeSongService from './tributeSong.service';
import TributePhotoService from './tributePhoto.service';
import ServicesService from './services.service';
import EventsService from './events.service';
import BlobService from './blob.service';
import AdminToolService from './adminTool.service';

const initApiServices = token => {
    return {
        adminTool: AdminToolService(token),
        tributeVideo: TributeVideoService(token),
        tributeSong: TributeSongService(token),
        tributePhoto: TributePhotoService(token),
        tributeTemplate: TributeTemplateService(token),
        services: ServicesService(token),
        events: EventsService(token),
        blob: BlobService,
    };
};

export default initApiServices;
