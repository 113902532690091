import { getEmbedCode } from 'videojs-flash';
import ApiService from './api.service';

const FuneralHomeService = token => {
    const api = ApiService.createApiInstance(token);

    return {
        getSettingsAdmin(funeralHomeId) {
            return api.get(`/funeralhomes/settings/admin/${funeralHomeId}`);
        },
        getSettings(funeralHomeId) {
            return api.get(`/funeralhomes/settings/${funeralHomeId}`);
        },
        updateSettingsAdmin(data) {
            return api.put(`/funeralhomes/settings/admin`, data);
        },
        updateSettings(data) {
            return api.put(`/funeralhomes/settings`, data);
        },
        getBetaAccess(fhId) {
            return api.get(`/funeralhomes/settings/beta/${fhId}`);
        },
    };
};

export default FuneralHomeService;
