import ApiService from './api.service';

const ServicesService = token => {
    const api = ApiService.createApiInstance(token);

    return {
        create(data) {
            return api.post(`/Services`, data);
        },
        get(id) {
            return api.get(`/Services/${id}`);
        },
        adminGet(serviceId) {
            return api.get(`/Services/admin-edit/${serviceId}`);
        },
        getBySlugAndPin(slug, pin) {
            return api.get(`/Services/${slug}/${pin}`);
        },
        dumpRedisCache(serviceId) {
            return api.post(`/Services/dump-redis-cache/${serviceId}`);
        },
        getEmbedCode(serviceId) {
            return api.get(`/embedcodes/service/${serviceId}`);
        },
    };
};

export default ServicesService;
