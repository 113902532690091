<template>
    <div>
        <div>
            <TributeHeader
                v-if="!displayOnboarding"
                ref="tributeHeader"
                :tribute-video="tributeVideo"
                :event="event"
                :service="service"
                :deadline="tributeVideo.deadline"
                :standard-render="standardRender"
                :copyrightSafeRender="copyrightSafeRender"
                :tabs="tributeTabs"
                :tabIndex="tabIndex"
                @open-main-photo-modal="initMainPhotoSelectModal"
                @open-settings-modal="initSettingsModal"
                @tab-index="val => (tabIndex = val)"
            />

            <div v-show="displayOnboarding" class="tribute-onboarding">
                <tribute-onboarding
                    ref="tributeOnboarding"
                    :service="service"
                    :event="event"
                    :service-events="serviceEvents"
                    :minPhotos="minPhotos"
                    :mainPhotoUrl="tributeVideo.mainPhotoUrl"
                    :processingUploads="processingUploads"
                    :betaAccess="betaAccess"
                    @refresh-photos="handlePhotoRefresh"
                    @refresh-deadline="time => refreshDeadline(time)"
                    @onboard-override="handleOnboardingOverride"
                    @refresh-share-tab="shareTabRefreshKey++"
                />
            </div>

            <div v-show="!displayOnboarding">
                <v-alert v-if="outOfSyncChanges.length > 0" class="mt-4" text color="warning">
                    <div>
                        <h5>Video Out of Sync!</h5>
                        <span v-if="outOfSyncChanges.length == 1"
                            >There has been {{ outOfSyncChanges.length }} change since last render.</span
                        >
                        <span v-else="outOfSyncChanges.length == 1"
                            >There have been {{ outOfSyncChanges.length }} changes since last render.</span
                        >
                        Please
                        <v-btn x-small outlined color="warning" @click="tabIndex = 'summary'" style="cursor: pointer"
                            >re-render</v-btn
                        >
                        or
                        <v-btn x-small outlined color="warning" @click="openTributeChangelog" style="cursor: pointer"
                            >undo changes</v-btn
                        >
                        .
                    </div>

                    <!-- <div>
                        <div class="d-flex align-items-center justify-space-between">
                            <div>
                                <v-btn
                                    color="warning"
                                    :disabled="loading"
                                    @click="undoLastChange"
                                    class="mr-2"
                                    text
                                    x-small
                                >
                                    <font-awesome-icon
                                        style="font-size: 0.8rem"
                                        icon="fa-solid fa-rotate-left"
                                    ></font-awesome-icon>
                                </v-btn>
                            </div>

                            <v-btn @click="openTributeChangelog" text>View Changes</v-btn>
                        </div>
                    </div> -->
                </v-alert>

                <!-- <div
                    style="background-color: #f8f8f8; padding: 10px; margin-bottom: 16px; border-radius: 5px"
                    v-if="outOfSyncChanges.length > 0"
                    class="d-flex align-items-center justify-space-between"
                >
                    <div>
                        <v-btn :disabled="loading" @click="undoLastChange" class="mr-2" text x-small>
                            <font-awesome-icon
                                style="font-size: 0.8rem"
                                icon="fa-solid fa-rotate-left"
                            ></font-awesome-icon>
                        </v-btn>
                        <span>{{ outOfSyncChanges.length }} changes since render</span>
                    </div>

                    <v-btn @click="openTributeChangelog" text>View Changes</v-btn>
                </div> -->

                <div>
                    <!-- @refresh-photos="id => $emit('refresh-photos', id)" -->
                    <TributeTabSection
                        ref="tabs"
                        @changeTab="tab => handleChangeTab(tab)"
                        @delete-photo="photo => handlePhotoDelete(photo)"
                        @devModeToggle="val => handleDevModeToggle(val)"
                        @refresh-photos="handlePhotoRefresh"
                        @refresh-tribute-render="getTributeRender(tributeVideo.id)"
                        @refresh-sync="checkOutOfSync"
                        @refresh-tribute="event => $emit('refresh-tribute', event)"
                        @render-deleted="handleRenderDeleted"
                        @render-started="handleRenderStarted"
                        @template-customize-main-photo="mainPhotoModal = true"
                        @hide-event="event => $emit('hide-event', event)"
                        @unhide-event="event => $emit('unhide-event', event)"
                        @tab-index="val => (tabIndex = val)"
                        :tabIndex="tabIndex"
                        :event="event"
                        :service="service"
                        :devMode="devMode"
                        :minPhotos="minPhotos"
                        :standardRender="standardRender"
                        :copyrightSafeRender="copyrightSafeRender"
                        :onboarding="displayOnboarding"
                        :refreshKey="refreshKey"
                        :shareTabRefreshKey="shareTabRefreshKey"
                    />
                </div>

                <!-- START: SUPER ADMIN BUTTON CONTAINER -->
                <div v-if="$auth.role.includes('SuperAdmin')" class="super-admin-button-container">
                    <h3>Super Admin Edit</h3>

                    <v-btn @click="openTributeAdminModal" block color="#ff530d" dark class="mt-4">Tribute</v-btn>
                    <v-btn
                        @click="openRenderAdminModal('standard')"
                        v-if="standardRender.id"
                        block
                        color="#ff530d"
                        dark
                        class="mt-4"
                        >Original Render</v-btn
                    >

                    <v-btn
                        @click="openRenderAdminModal('copyright')"
                        v-if="copyrightSafeRender.id"
                        block
                        color="#ff530d"
                        dark
                        class="mt-4"
                        >Public Render</v-btn
                    >
                    <v-btn @click="openTributeChangelog" block color="#ff530d" dark class="mt-4">Changelog</v-btn>
                </div>
                <!-- END: SUPER ADMIN BUTTON CONTAINER -->

                <div class="text-center mt-4">
                    <!-- <v-btn
                        v-if="event.hidden && devMode && tabIndex == 'gallery'"
                        small
                        class="p-3"
                        color="warning"
                        @click="handleUnHideClick(event)"
                        >Un Hide</v-btn
                    > -->
                    <v-btn
                        v-if="!event.hidden && $auth.role.includes('SuperAdmin')"
                        small
                        class="p-3"
                        color="error"
                        @click="openDeleteEventConfirm(event)"
                        >Delete Tribute</v-btn
                    >
                </div>
            </div>
        </div>

        <v-dialog max-width="750px" v-model="standardRenderAdminModal">
            <tribute-render-super
                ref="renderSuper"
                @close="closeRenderAdminModal('standard')"
                v-if="standardRender.id"
                :render="standardRender"
            ></tribute-render-super>
        </v-dialog>

        <v-dialog max-width="750px" v-model="copyrightSafeRenderAdminModal">
            <tribute-render-super
                ref="renderSuper"
                @close="closeRenderAdminModal('copyright')"
                v-if="copyrightSafeRender.id"
                :render="copyrightSafeRender"
            ></tribute-render-super>
        </v-dialog>

        <!-- START: Tribute Admin modal -->

        <!-- END: Tribute Admin modal -->
        <TributeSuperModal
            v-if="event.id"
            v-model="tributeAdminModal"
            :eventId="event.id"
            @refresh-share-links="handleLinkRefresh"
        />
        <!-- START: Main photo modal -->

        <!-- END: Main photo modal -->

        <!-- START: Confirmation Modal -->
        <v-dialog max-width="400px" v-model="confirmationModal">
            <v-card class="p-3">
                <v-card-title>Update main photo?</v-card-title>
                <div class="p-3" style="display: flex; justify-content: space-between">
                    <v-btn @click="cancelMainPhotoUpdate">Cancel</v-btn>
                    <v-btn @click="submitMainPhotoUpdate(tributeVideo.eventId, selectedPhoto.id)" color="primary"
                        >Confirm</v-btn
                    >
                </div>
            </v-card>
        </v-dialog>
        <!-- END: Confirmation Modal -->

        <!-- START: Confirmation Modal -->
        <v-dialog max-width="400px" v-model="deleteEventModal">
            <v-card>
                <v-card-title>Delete Tribute Event</v-card-title>
                <v-card-text>Delete tribute event for {{ service.firstName }} {{ service.lastName }}?</v-card-text>

                <div class="p-4 pt-0 d-flex" style="justify-content: space-between">
                    <v-btn @click="deleteEventModal = false">Cancel</v-btn>
                    <v-btn :loading="loading" :disabled="loading" @click="handleDelete(event)" color="error"
                        >Confirm</v-btn
                    >
                </div>
            </v-card>
        </v-dialog>

        <!-- START: Settings Modal -->
        <v-dialog @click:outside="cancelSettingsUpdate" max-width="400px" eager v-model="settingsModal">
            <v-card class="tribute-settings-modal">
                <v-card-title>Settings</v-card-title>
                <!-- <v-card-text>Delete tribute event for {{ service.firstName }} {{ service.lastName }}?</v-card-text> -->
                <div class="calendar-wrapper px-6">
                    <calendar
                        ref="deadlineCalendar"
                        inline
                        @inline-save="submitTributeSettings"
                        datetime
                        v-model="tributeDeadline"
                        label="Deadline"
                        disable-past-dates
                        clearable
                    />
                </div>

                <!-- <div class="px-6">
                    <v-switch label="Separate Player" v-model="separatePlayer"></v-switch>
                    <v-switch label="Only Allow Uploads" v-model="uploadsOnly"></v-switch>
                </div> -->
                <div v-if="loopTribLink" class="ma-6 loop-link-section">
                    <h6 class="pa-4" style="font-size: 1.1rem">Tribute Video Loop</h6>
                    <div class="pa-4 pt-0 d-flex justify-content-between">
                        <v-btn color="success" depressed @click="openWindow(loopTribLink)">
                            <font-awesome-icon icon="fa-regular fa-arrow-up-right-from-square" class="mr-2" />
                            Open</v-btn
                        >
                        <v-btn dark depressed class="ml-2" @click="copyToClipboard(loopTribLink)"
                            ><font-awesome-icon icon="fa-regular fa-clone" class="mr-2" />
                            Copy Link
                        </v-btn>
                    </div>
                </div>
                <div class="p-4 pt-0 d-flex" style="justify-content: space-between">
                    <v-btn @click="cancelSettingsUpdate" depressed>Cancel</v-btn>
                    <v-btn @click="submitTributeSettings" dark depressed color="primary">Confirm</v-btn>
                </div>
            </v-card>
        </v-dialog>
        <!-- END: Settings Modal -->

        <changelog-modal
            @refresh-summary="refreshSummary"
            @refresh-sync="checkOutOfSync"
            @undo-all-success="handleUndoAllSuccess"
            v-if="tributeVideo.id"
            :tributeId="tributeVideo.id"
            ref="changelog"
        ></changelog-modal>

        <TributeUploadModal
            ref="profileUploadModal"
            v-if="event.id"
            :eventId="event.id"
            profile-photo-upload
        ></TributeUploadModal>

        <ProfilePhotoModal
            v-if="event.id"
            ref="profileSelectModal"
            :eventId="event.id"
            @init-profile-upload="initProfilePhotoUploadModal"
        ></ProfilePhotoModal>
    </div>
</template>

<script>
import moment from 'moment';
import draggable from 'vuedraggable';
import TributeTabSection from '@/components/ManageService/Tribute/TributeTabSection.vue';
import TributeOnboarding from '@/components/ManageService/Tribute/TributeOnboarding.vue';
import Calendar from '@/components/ui/Calendar.vue';
import { mapActions } from 'vuex';
import { eventStates, tributeChangelogActions } from '@/constants';
import TributeRenderSuper from '@/components/ManageService/super-admin/TributeRenderSuper.vue';
import ChangelogModal from '@/components/ManageService/Tribute/ChangelogModal.vue';
import BrandedModal from '@/components/ui/BrandedModal.vue';
import { service } from 'powerbi-client';
import TributeHeader from '@/components/ManageService/Tribute/TributeHeader.vue';
import TributeUploadModal from '@/components/ManageService/Tribute/TributeUploadModal.vue';
import ProfilePhotoModal from '@/components/ManageService/Tribute/ProfilePhotoModal.vue';
import TributeSuperModal from '@/components/ManageService/Tribute/TributeSuperModal.vue';
import { betaFeaturesEnum } from '@/constants';
import FuneralHomeService from '@/services/funeralHome.service';

export default {
    name: 'TributeEvent',
    props: {
        type: {
            type: Number,
            required: true,
        },
        event: {
            type: Object,
            required: true,
        },
        service: {
            type: Object,
            required: true,
        },
        serviceEvents: {
            type: Array,
        },
        onboarding: {
            type: Boolean,
            default: true,
        },
        refreshKey: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        tributeVideo() {
            return this.$store.state.tributeVideo;
        },
        totalPages() {
            return Math.ceil(this.totalPhotos / this.pageSize);
        },
        photos() {
            return this.$store.state.tributeVideo.photos;
        },
        displayOnboarding() {
            return this.onboarding && !this.onboardingOverride;
        },
        photosOptions() {
            return this.$store.state.tributeVideo.photosOptions;
        },
        displayShareTab() {
            return this.tributeVideo.deeplink || this.tributeVideo.familyLink;
        },
        filteredProfilePhotoOptions() {
            return this.profilePhotosOptions.filter(x => x.mediaType == 0);
        },
        defaultStartingTab() {
            return this.betaAccess === betaFeaturesEnum.ALL ? 'editor' : 'share';
        },
    },
    components: {
        TributeTabSection,
        TributeOnboarding,
        draggable,
        Calendar,
        TributeRenderSuper,
        ChangelogModal,
        BrandedModal,
        TributeHeader,
        TributeUploadModal,
        ProfilePhotoModal,
        TributeSuperModal,
    },
    data() {
        return {
            shareTabRefreshKey: 0,
            eventStates,
            tributeChangelogActions,
            modalMainAnimated: false,
            eventFormData: {},

            message: 'here!',
            tributeAdminModal: false,
            mainPhotoUrl: null,
            mainPhotoSelectModal: false,
            eventPhotos: [],
            pageNumber: 1,
            pageSize: 12,
            totalPhotos: 0,
            selectedPhoto: null,
            confirmationModal: false,
            deleteEventModal: false,
            devMode: false,
            minPhotos: 1,
            confirmationPhotoUrl: null,
            confirmingPhoto: false,
            loading: false,
            tabIndex: 'share',
            processingUploads: false,
            uploadProgress: 0,
            showProgress: false,
            settingsModal: false,
            uploadsOnly: false,
            separatePlayer: false,
            tributeDeadline: '',
            modalCalendarActive: false,
            loopTribLink: null,
            displayCopyrightRender: false,
            standardRender: {},
            copyrightSafeRender: {},
            standardRenderAdminModal: false,
            copyrightSafeRenderAdminModal: false,
            incomingUploadCount: 0,
            outOfSyncChanges: [],
            tabSectionRefreshKey: 0,
            outOfSyncChangesModal: false,
            adminMaxTributeItems: 0,
            onboardingOverride: false,
            funeralHomeApi: null,
            betaAccess: 0,
            commonTabs: [
                { label: 'Share', value: 'share' },
                { label: 'Slides', value: 'gallery' },
                // { label: 'Stories', value: 'stories' },
                { label: 'Theme', value: 'templates' },
                { label: 'Music', value: 'music' },
                { label: 'Summary', value: 'summary' },
            ],
            tributeTabs: [],
            stepHeaders: [
                { text: 'Overview', value: 1 },
                { text: 'Identify', value: 2 },
                { text: 'Upload Photos', value: 3 },
            ],
        };
    },
    watch: {
        tributeVideo: {
            handler(val) {
                if (val.mainPhotoId && this.tributeVideo.mainPhotoUrl != val.mainPhotoUrl) {
                    this.refreshTributeVideoData();
                }
            },
            deep: true,
        },
        outOfSyncChanges() {
            if (this.outOfSyncChanges.length == 0) {
                this.outOfSyncChangesModal = false;
            }
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        ...mapActions('tributeVideo', [
            'updateTributeVideo',
            'updateTributeVideoPhotos',
            'updateTributeVideoSelectedPhotos',
            'updateTributeVideoSelectedTemplates',
            'updateTributeVideoSelectedSongs',
            'resetTributeVideoState',
        ]),
        handleLinkRefresh() {
            this.shareTabRefreshKey++;
        },
        initMainPhotoSelectModal() {
            if (this.tributeVideo.totalPhotos === 0) {
                this.initProfilePhotoUploadModal();
                return;
            }

            const profileSelectModal = this.$refs.profileSelectModal;
            if (profileSelectModal) {
                profileSelectModal.open();
            }

            const profileUploadModal = this.$refs.profileUploadModal;

            if (!profileUploadModal || typeof profileUploadModal.open !== 'function') return;

            profileUploadModal.close();
        },
        initProfilePhotoUploadModal() {
            const profileUploadModal = this.$refs.profileUploadModal;

            if (!profileUploadModal || typeof profileUploadModal.open !== 'function') return;

            profileUploadModal.open();

            this.mainPhotoSelectModal = false;
        },
        initSettingsModal() {
            this.settingsModal = true;
        },
        handleOnboardingOverride() {
            this.onboardingOverride = true;
        },
        handleUndoAllSuccess() {
            this.outOfSyncChanges = [];
            this.outOfSyncChangesModal = false;
            this.refreshSummary();
            this.checkOutOfSync();
        },
        updateMaxItems() {
            this.loading = true;

            this.axiosInstance
                .post(`/TributeVideoPhoto/update-max-items/${this.event.id}/${this.adminMaxTributeItems}`)
                .then(response => {
                    this.tributeVideo.maxItems = response.data.maxItems;
                    this.showSnackbar({ message: 'Max slides updated' });
                    this.tributeAdminModal = false;
                })
                .catch(error => {
                    console.log(error);
                    this.showSnackbar({ message: 'Error updating max slides', color: 'error' });
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        refreshSummary() {
            if (this.$refs.tabs) {
                this.$refs.tabs.refreshSummary();
            }
        },

        initOutOfSyncChangesModal() {
            this.outOfSyncChangesModal = true;
        },
        changeWasRendered(item) {
            if (this.lastRenderDate == null) {
                return false;
            } else {
                return item.createDate < this.lastRenderDate;
            }
        },
        undoAllChanges() {
            this.loading = true;
            this.axiosInstance
                .post(`/TributeVideo/changelog/undo-all-since-render/${this.tributeVideo.id}`)
                .then(resp => {
                    this.showSnackbar({ message: 'Undo successful' });
                    this.outOfSyncChanges = [];
                    this.outOfSyncChangesModal = false;
                    this.refreshSummary();
                    this.checkOutOfSync();
                })
                .catch(error => {
                    console.log(error, 'error @ undo');
                    this.showSnackbar({ message: 'Error occurred during undo', color: 'error' });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        undoLastChange() {
            if (this.outOfSyncChanges.length == 0) {
                this.showSnackbar({ message: 'Change record not found.', color: 'error' });
                return;
            }

            const targetRecord = this.outOfSyncChanges[0];

            this.undoChange(targetRecord);
        },
        undoChange(item) {
            if (this.mainTributePhotoId > 0) {
                if (
                    item.action == 'Add' &&
                    item.tableName == 'TributeVideoPhoto' &&
                    item.itemId == this.mainTributePhotoId
                ) {
                    this.showSnackbar({ message: 'Cannot remove main photo', color: 'error' });
                    this.selectedRecord = null;
                    this.recordToDelete = null;
                    this.continuationToken = null;
                    this.showRaw = false;
                    this.results = [];
                    return;
                }
            }

            const found = this.outOfSyncChanges.find(x => x.id == item.id);

            if (found) {
                this.loading = true;
                this.axiosInstance
                    .post(`/TributeVideo/changelog/undo/${item.tributeId}/${item.id}`)
                    .then(resp => {
                        if (found) {
                            const index = this.outOfSyncChanges.indexOf(found);
                            this.outOfSyncChanges.splice(index, 1);
                        }
                        this.showSnackbar({ message: 'Undo successful' });
                        this.refreshSummary();
                        this.checkOutOfSync();
                    })
                    .catch(error => {
                        console.log(error, 'error @ undo');
                        this.showSnackbar({ message: 'Error occurred during undo', color: 'error' });
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        openTributeChangelog() {
            if (this.$refs.changelog) {
                this.$refs.changelog.openModal();
            }
        },
        handlePhotoRefresh() {
            this.$emit('refresh-photos', this.event);
        },
        closeRenderAdminModal(type) {
            switch (type) {
                case 'standard':
                    this.standardRenderAdminModal = false;

                    if (this.tributeVideo.id) {
                        this.getTributeRender(this.tributeVideo.id);
                        this.checkOutOfSync();
                    }
                    break;
                case 'copyright':
                    this.copyrightSafeRenderAdminModal = false;

                    break;
                default:
                    console.log('Unhandled case @ close render admin modal');
            }
        },
        openRenderAdminModal(type) {
            switch (type) {
                case 'standard':
                    this.standardRenderAdminModal = true;
                    break;
                case 'copyright':
                    this.copyrightSafeRenderAdminModal = true;
                    break;
                default:
                    console.log('Unhandled case @ openRenderAdminModal');
            }
        },
        cancelSettingsUpdate() {
            if (this.tributeVideo.deadline) {
                this.$refs.deadlineCalendar.tempDate = false;
                this.tributeDeadline = moment(this.tributeVideo.deadline).toISOString(true);
            }

            this.uploadsOnly = this.tributeVideo.uploadsOnly;
            this.separatePlayer = this.tributeVideo.separatePlayer;
            this.settingsModal = false;
        },
        checkOutOfSync() {
            if (this.standardRender.id) {
                this.axiosInstance.get(`/TributeVideoRender/check-out-of-sync/${this.standardRender.id}`).then(resp => {
                    this.outOfSyncChanges = resp.data.outOfSyncChanges;

                    // if (this.$refs.renderSuper) {
                    //     this.$refs.renderSuper.refresh();
                    // }
                });
            }
        },
        refreshDeadline(dt) {
            this.tributeDeadline = moment(dt).toISOString(true);
        },
        handlePhotoDelete(photo) {
            $emit('delete-photo', photo);
            this.refreshTributeVideoData();
        },
        refreshTributeVideoData() {
            if (this.tributeVideo?.eventId) {
                this.axiosInstance
                    .get(`/TributeVideo/get-tribute-by-event/${this.tributeVideo?.eventId}`)
                    .then(resp => {
                        if (this.service.slug && this.event.convertedVideo) {
                            this.getTributeRender(this.tributeVideo.id);
                        }
                        this.updateTributeVideo({ ...resp.data });
                        this.$emit('refresh-photos', this.event);
                    });
            }
        },
        handleRenderStarted() {
            this.tributeTabs = [{ label: 'Video', value: 'video' }, ...this.tributeTabs];
            this.tabIndex = 'video';
            this.getTributeRender(this.tributeVideo.id);
        },
        handleRenderDeleted() {
            this.tributeTabs = this.commonTabs;
            this.tabIndex = 'summary';
            this.standardRender = {};
            this.copyrightSafeRender = {};
        },
        hideEvent(event) {
            this.$emit('hide-event', event);
        },
        handleChangeTab(tab) {
            this.tabIndex = tab;

            if (tab == 'video') return;
            const main = document.getElementById('scroll');
            main.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
        openWindow(url) {
            window.open(url, '_blank');
        },
        copyToClipboard(string) {
            navigator.clipboard.writeText(string);
            this.showSnackbar({ message: 'Link copied' });
        },
        submitTributeSettings() {
            // If there is an event time passed in, just check if it's older than 30 days.
            let time = new Date(this.tributeDeadline).toISOString();

            if (this.$refs.deadlineCalendar.renderedDate == '') {
                // time = new Date('0001-01-01T00:00:00');
                time = null;
            }

            let data = {
                deadline: time,
                separatePlayer: this.separatePlayer,
                uploadsOnly: this.uploadsOnly,
            };

            this.axiosInstance
                .post(`TributeVideo/settings/${this.tributeVideo.id}`, data)
                .then(resp => {
                    this.$emit('refresh-service', this.event);
                    this.showSnackbar({ message: 'Upload deadline updated' });
                    this.settingsModal = false;
                })
                .catch(err => {
                    console.log(err, 'error');
                    this.showSnackbar({
                        message: 'Error updating upload deadline',
                        color: 'error',
                    });
                });
        },

        handleEventFormSubmission: function () {
            if (this.eventFormData.userId === 0 || this.eventFormData.userId === null) {
                this.eventFormData.userId = this.$auth.user['https://memoryshare.com/user_id'];
                if (this.eventFormData.userId < 1) {
                    this.eventFormData.userId = 0;
                }
            }

            let error = false;

            if (this.eventFormData.title.length == 0) {
                this.showSnackbar({ message: 'Title cannot be blank', color: 'error' });
                error = true;
            }

            if (error) return;

            const data = {
                ...this.eventFormData,
                serviceId: this.service.id,
                eventType: 0,
                userId: this.eventFormData.userId ? this.eventFormData.userId : 0,
            };

            this.$emit('updateEvent', { data: data, id: this.event.id });
        },
        handleMainImgSrc(url) {
            if (url && url.includes('/tribute-photos/')) {
                const imgPath = url.split('/tribute-photos/')[1];
                return process.env.VUE_APP_IMG_KIT_BASE + 'tr:w-500,h-500,fo-face/' + imgPath;
            }
        },
        handleDevModeToggle(val) {
            this.devMode = val;
        },
        toggleDevMode() {
            this.devMode = !this.devMode;
        },
        submitMainPhotoUpdate(eventId, photoId) {
            this.loading = true;
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + `/TributeVideoPhoto/update-main-photo/${eventId}/${photoId}`)
                .then(resp => {
                    this.confirmationModal = false;
                    this.mainPhotoModal = false;
                    this.refreshTributeVideoData();
                })
                .catch(err => {
                    console.log(err, 'err');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        cancelMainPhotoUpdate() {
            this.confirmationModal = false;
            this.selectedPhoto = null;
        },
        initConfirmModal(item) {
            this.selectedPhoto = item;
            this.confirmationModal = true;
        },
        handleMainClass(item) {
            if (this.tributeVideo.mainPhotoId) {
                if (item.id == this.tributeVideo.mainPhotoId) {
                    return 'elevation-8';
                }
            }
        },
        openMainPhotoModal() {
            this.mainPhotoModal = true;
            this.pageNumber = 1;
        },
        openTributeAdminModal() {
            this.tributeAdminModal = true;
        },
        openDeleteEventConfirm() {
            this.deleteEventModal = true;
        },
        handleDelete(event) {
            this.loading = true;
            this.$emit('deleteEvent', event);
        },
        getPhotos(eventId, params) {
            this.loading = true;
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/TributeVideoPhoto/event-photos/${eventId}`, {
                    params: params,
                })
                .then(resp => {
                    this.eventPhotos = resp.data.photos;
                    this.totalPhotos = resp.data.total;
                    this.updateTributeVideoPhotos(resp.data.photos);
                    this.updateTributeVideo({ totalPhotos: resp.data.total });
                })
                .catch(err => {
                    console.log(err, 'err');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getSelectedTemplates(id) {
            this.axiosInstance
                .get(`TributeVideo/selected-templates/${id}`)
                .then(resp => {
                    if (resp.data) {
                        this.updateTributeVideoSelectedTemplates(resp.data.templates);
                    }
                })
                .catch(err => {
                    console.log(err, 'template json error');
                });
        },
        getSelectedSongs(id, copyrightPlaylist = false) {
            this.axiosInstance
                .get(`TributeVideo/selected-songs/${id}`)
                .then(resp => {
                    if (resp.data && !copyrightPlaylist) {
                        this.updateTributeVideoSelectedSongs(resp.data);
                    }
                })
                .catch(err => {
                    console.log(err, 'template json error');
                });
        },
        getTributeVideo(id) {
            return this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/TributeVideo/get-tribute-by-event/${id}`)
                .then(response => {
                    this.updateTributeVideo(response.data);
                    return this.tributeVideo;
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },

        async getTributeRender(tributeId, copyrightSafe = false) {
            if (tributeId) {
                this.loading = true;
                return this.axiosInstance
                    .get(`/TributeVideoRender/${tributeId}?copyrightSafe=${copyrightSafe}`)
                    .then(resp => {
                        if (resp.data) {
                            if (copyrightSafe) {
                                this.copyrightSafeRender = resp.data;
                            } else {
                                this.standardRender = resp.data;
                            }
                        }
                    })
                    .catch(error => {
                        if (error.response && error.response.status == 404) {
                            return null;
                        } else {
                            console.log('error', error);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        getStoryEvent(id) {
            this.loading = true;
            return this.axiosInstance
                .get(`/Events/${id}`)
                .then(resp => {
                    return resp.data;
                })
                .catch(error => {
                    console.log(error, 'error');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getActiveTabs() {
            var activeTabs = this.commonTabs;

            if (this.betaAccess === betaFeaturesEnum.ALL) {
                activeTabs = [
                    { label: 'Share', value: 'share' },
                    { label: 'Editor', value: 'editor' },
                ];
            } else {
                activeTabs = [...this.commonTabs];
            }

            if (this.standardRender.id || this.copyrightSafeRender.id) {
                activeTabs = [{ label: 'Video', value: 'video' }, ...activeTabs];
            }

            return activeTabs;
        },
        setStartingTabIndex() {
            const tributeHeader = this.$refs.tributeHeader;
            this.tributeTabs = this.getActiveTabs();
            var startingTab = this.defaultStartingTab;

            if (this.standardRender.id || this.copyrightSafeRender.id) {
                startingTab = 'video';
            }

            if (tributeHeader && typeof tributeHeader.setTabIndex == 'function') {
                tributeHeader.setTabIndex(startingTab);
            }
        },
        async getBetaAccess(id) {
            try {
                if (!id) throw new Error('Invalid id');

                var resp = await this.funeralHomeApi.getBetaAccess(id);
                if (resp.data) {
                    return resp.data;
                }

                return betaFeaturesEnum.NONE;
            } catch (error) {
                console.log(error, 'error');
                this.showSnackbar({ message: 'Error fetching settings', color: 'error' });
                return betaFeaturesEnum.NONE;
            }
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.createAxiosInstance();

        this.funeralHomeApi = FuneralHomeService(this.token);

        this.betaAccess = await this.getBetaAccess(this.event.funeralHomeId);

        this.separatePlayer = this.service.separatePlayer;

        if (this.service.slug && this.event.convertedVideo) {
            this.loopTribLink = `${process.env.VUE_APP_VIDEO}/video/${this.service.slug}/tribute-video`;
        }

        await this.getTributeRender(this.tributeVideo.id, false);
        await this.getTributeRender(this.tributeVideo.id, true);

        this.setStartingTabIndex();

        if (this.tributeVideo.storyEventId) {
            const storyEvent = await this.getStoryEvent(this.tributeVideo.storyEventId);
            this.updateTributeVideo({ storyEvent: storyEvent });
        }

        this.eventFormData = {
            id: this.event.id,
            title: this.event.title,
            time: this.service.deathDate ? moment(this.service.deathDate)?.toISOString() : null,
            live: false,
            private: this.event?.private || false,
            introVideo: false,
            userId: this.event?.userId,
            eventType: 0,
            serviceId: this.service.id,
        };

        if (this.tributeVideo.id) {
            let params = {
                pageNumber: this.pageNumber - 1,
                pageSize: this.pageSize,
            };
            // this.getPhotos(this.tributeVideo.eventId, params);
            this.getSelectedSongs(this.tributeVideo.id);
            this.getSelectedTemplates(this.tributeVideo.id);
        }

        if (this.tributeVideo.deadline) {
            let deadline = new Date(this.tributeVideo.deadline);
            let deadlineOffset = deadline.getTimezoneOffset() / 60;
            deadline.setHours(deadline.getHours() - deadlineOffset);
            this.tributeDeadline = deadline.toISOString();
        }

        this.adminMaxTributeItems = this.tributeVideo.maxItems;

        this.uploadsOnly = this.tributeVideo.uploadsOnly;

        this.checkOutOfSync();
    },
    sockets: {
        async NotifyTributeRender(data) {
            if (this.tributeVideo.id == data.tributeVideoId) {
                if (data.renderType == 0) {
                    await this.getTributeRender(this.tributeVideo.id, false);
                    this.checkOutOfSync();
                } else if (data.renderType == 1) {
                    await this.getTributeRender(this.tributeVideo.id, true);
                }

                this.tributeTabs = this.getActiveTabs();
            }
        },
        async NotifyUpload(data) {
            if (data.id === this.event.id) {
                this.refreshSummary();

                if (this.displayOnboarding) {
                    this.$emit('refresh-photos', this.event);
                }
            }

            // if (data.id == this.event.id && !this.rotatingImages) {
            //     if (this.tributeVideo.eventId) {
            //         this.pageNumber = 0;
            //         this.pageSize = this.photos.length >= 24 ? this.photos.length : 24;
            //         await this.updatePhotoStore(this.pageNumber, this.pageSize, false, false);
            //         this.checkOutOfSyncPhotos(this.event.id);
            //         this.$emit('refresh-sync');
            //     }
            // }
        },
        NotifyMainPhotoReplace(data) {
            if (data.eventId == this.tributeVideo.eventId) {
                this.refreshTributeVideoData();
                this.$emit('refresh-photos', this.event);
            }
        },
        NotifyTributeSongsUpdate(data) {
            if (data.tributeId == this.tributeVideo.id && !this.tributeVideo.loading) {
                const copyrightPlaylist = data.playlistType == 1;
                this.getSelectedSongs(this.tributeVideo.id, copyrightPlaylist);
            }
        },
        // NotifyTributeThemeUpdate(data) {
        //     if (data && data.tributeId && data.tributeId > 0 && data.tributeId == this.tributeVideo.id) {
        //         if (data?.template?.id > 0) {
        //             this.updateTributeVideoSelectedTemplates([data.template]);
        //         } else {
        //             this.updateTributeVideoSelectedTemplates([]);
        //         }
        //     }
        // },
    },
};
</script>
<style lang="scss">
.tribute-onboarding {
    .hidden {
        opacity: 0;
        height: 0;
        width: 0;
    }
}
.uploader-wrap--onboarding {
    border: 3px dashed #d3d3d3;
    border-radius: 40px;
    margin: 25px 0;
    #drop-zone {
        height: 200px;
        border-radius: 40px;
        div {
            border-radius: 40px;

            button {
                border-radius: 40px;
            }
        }
    }
    .uppy-DragDrop-inner {
        border-radius: 40px;
        padding: 0;
        height: 100%;
    }
}
.main-photo-avatar:hover {
    cursor: pointer;
}
.main-photo-avatar {
    // border: 2px solid red;
}

.super-admin-button-container {
    background: #f8f8f8;
    padding: 1rem 3rem;
    margin: 1rem 0;
    gap: 2rem;
    text-align: center;
    border-radius: 4px;
}

.tribute-settings-modal {
    // .calendar-wrapper {
    //     height: 73px;
    //     transition: height 0.3s ease;
    // }
    // .calendar-wrapper--active {
    //     height: 520px !important;
    // }

    .calendar-wrapper {
        .main-container {
            position: relative;
            top: 0;
            margin-bottom: 18px;
        }
    }

    .loop-link-section {
        border: 1px solid #d3d3d3;
        border-radius: 5px;
    }
}

.tribute-admin-event-div {
    border: 1px solid #d8d8d8;
    border-radius: 5px;
}

.deadline-link:hover {
    text-decoration: underline;
}

.shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
}
@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}
</style>
