<template>
    <AppLayout :has-background="true" :contained="true">
        <v-card style="padding: 1rem">
            <v-tabs show-arrows v-model="tab" color="#0d5259">
                <v-tab href="#admins">Admins</v-tab>
                <v-tab href="#credits">Credits</v-tab>
                <v-tab href="#embeds">Embed Codes</v-tab>
                <v-tab href="#search">Stream Search</v-tab>
                <v-tab href="#user">User Search</v-tab>
                <v-tab href="#indexing">Google Index</v-tab>
                <v-tab href="#bunny">Bunny Search</v-tab>
                <v-tab href="#analytics-email-jobs">Analytics Email Jobs</v-tab>
                <v-tab href="#redis">Redis</v-tab>
            </v-tabs>

            <v-tabs-items :value="tab">
                <v-tab-item value="admins" v-if="tab === 'admins'">
                    <AdminsTable />
                </v-tab-item>
                <v-tab-item value="credits" v-if="tab === 'credits'">
                    <CreditTable />
                </v-tab-item>
                <v-tab-item value="embeds" v-if="tab === 'embeds'">
                    <EmbedCodeTable />
                </v-tab-item>
                <v-tab-item value="search" v-if="tab === 'search'">
                    <StreamSearchTable />
                </v-tab-item>
                <v-tab-item value="user" v-if="tab === 'user'">
                    <user-search />
                </v-tab-item>

                <v-tab-item value="indexing" v-if="tab === 'indexing'">
                    <google-index-notify-update />
                </v-tab-item>

                <v-tab-item value="bunny" v-if="tab === 'bunny'">
                    <bunny-video-search />
                </v-tab-item>

                <v-tab-item value="analytics-email-jobs" v-if="tab === 'analytics-email-jobs'">
                    <analytics-email-job-table class="p-2" />
                </v-tab-item>

                <v-tab-item value="redis">
                    <RedisAdmin></RedisAdmin>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </AppLayout>
</template>

<script>
import CreditTable from '@/components/Tables/Admin/CreditsTable.vue';
import EmbedCodeTable from '@/components/Tables/Admin/EmbedCodes.vue';
import AdminsTable from '@/components/Tables/Admin/AdminsTable.vue';
import StreamSearchTable from '@/components/Tables/Admin/StreamSearchTable.vue';
import UserSearch from '@/components/Tables/Admin/UserSearch.vue';
import GoogleIndexNotifyUpdate from '@/components/Misc/GoogleIndexNotifyUpdate.vue';
import BunnyVideoSearch from '@/components/Misc/BunnyVideoSearch.vue';
import AnalyticsEmailJobTable from '@/components/Tables/Admin/AnalyticsEmailJobTable.vue';
import RedisAdmin from '@/components/Misc/RedisAdmin.vue';
export default {
    metaInfo: {
        title: 'Credits',
    },
    components: {
        CreditTable,
        EmbedCodeTable,
        AdminsTable,
        StreamSearchTable,
        UserSearch,
        GoogleIndexNotifyUpdate,
        BunnyVideoSearch,
        AnalyticsEmailJobTable,
        RedisAdmin,
    },
    data() {
        return {
            tab: 'admins',
        };
    },
    methods: {},
    async mounted() {},
};
</script>

<style>
.deleteModalBtns {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 15px;
}
.deleteModalCard {
    text-align: center;
}
</style>
