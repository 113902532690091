<template>
    <div :class="['manage-slides', { expanded: isExpanded }]" @wheel.passive="handleWheel">
        <div class="action-bar">
            <div class="left"></div>
            <div class="right-buttons">
                <button class="icon-button rounded wide" @click="toggleHeight">
                    <svg
                        class="mr-1"
                        :style="{
                            transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'width transform 0.5s',
                        }"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M4.16671 12.5L10 6.66667L15.8334 12.5"
                            stroke="#6B7280"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    {{ !isExpanded ? 'Explore Themes' : 'Back to Canvas' }}
                </button>
            </div>
        </div>

        <div :class="['slides-container', { expanded: isExpanded }]" ref="templatesWrapper">
            <div v-if="!loading" :class="['selected-template-wrapper', { expanded: isExpanded }]">
                <transition name="fade" mode="out-in">
                    <ThemeCard
                        :key="selectedTemplate.id"
                        :template="selectedTemplate"
                        :isSelected="true"
                        :onEdit="() => openModal()"
                    />
                </transition>
            </div>

            <div v-else class="theme-placeholder"></div>

            <div :class="['divider-wrapper', { expanded: isExpanded }]">
                <span :class="['header-text', { expanded: isExpanded }]">Choose your favorite Theme</span>
                <div v-if="selectedTemplate" :class="['divider', { expanded: isExpanded }]"></div>
            </div>

            <transition-group
                v-if="!loading"
                name="slide-animation"
                tag="div"
                :class="['templates-wrapper', { expanded: isExpanded }]"
            >
                <div v-for="(template, index) in filteredTemplates" :key="template.id" class="theme-card-wrapper">
                    <ThemeCard
                        :template="template"
                        :isSelected="false"
                        :onSelect="() => quickSelectTemplate(tributeVideo.id, template.id)"
                        :onPreview="() => previewTemplate(template)"
                        :loading="loading"
                    />

                    <div
                        v-if="filteredTemplates.length >= 5 && index === filteredTemplates.length - 5"
                        ref="observerSentinel"
                        class="observer-sentinel"
                    ></div>
                </div>
            </transition-group>
            <div v-else class="templates-wrapper">
                <div v-for="n in 10" :key="n" class="theme-placeholder"></div>
            </div>
        </div>

        <modify-theme-modal
            :isOpen="isModalOpen"
            :tributeId="tributeVideo.id"
            :previewSource="previewSource"
            :previewMods="previewMods"
            :currentFont="templateFont"
            :currentModifications="modifications"
            @close="closeModal"
            @save="updateTheme"
        />

        <preview-theme-modal
            :isOpen="isPreviewModalOpen"
            :tributeId="tributeVideo.id"
            :currentFont="templateFont"
            :currentModifications="modifications"
            :mainPhoto="mainPhotoSource"
            :templates="filteredTemplates"
            :selectedTemplateIndex="selectedTemplateIndex"
            @change-template="changeTemplate"
            @close="isPreviewModalOpen = false"
            @save="handlePreviewSave"
        />
    </div>
</template>

<script>
import TributeThemeService from '@/services/tributeTemplate.service';
import TributeVideoService from '@/services/tributeVideo.service';
import CrownIcon from '@/assets/icons/crownIcon.vue';
import ThemeCard from './Themes/ThemeCard.vue';
import { mapActions } from 'vuex';
import ModifyThemeModal from './Themes/EditTheme.vue';
import PreviewThemeModal from './Themes/PreviewTheme.vue';

export default {
    name: 'ManageThemes',
    components: {
        CrownIcon,
        ThemeCard,
        ModifyThemeModal,
        PreviewThemeModal,
    },
    props: {
        eventId: {
            type: Number,
            required: true,
        },
        tributeVideo: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isExpanded: {
            get() {
                return this.$store.state.tributeEditor.expanded;
            },
        },
        filteredTemplates() {
            return this.templates.filter(
                template => !this.selectedTemplate || template.id !== this.selectedTemplate.id,
            );
        },
    },
    watch: {
        filteredTemplates() {
            if (!this.observer) return;

            this.$nextTick(() => {
                this.observer.disconnect();

                if (this.$refs.observerSentinel) {
                    const sentinelEl = Array.isArray(this.$refs.observerSentinel)
                        ? this.$refs.observerSentinel[0]
                        : this.$refs.observerSentinel;
                    if (sentinelEl) {
                        this.observer.observe(sentinelEl);
                    }
                }
            });
        },
    },
    methods: {
        ...mapActions(['showSnackbar', 'block']),
        ...mapActions('tributeVideo', ['updateTributeVideo', 'updateTributeVideoSelectedTemplates']),
        ...mapActions('tributeEditor', ['toggleExpandedState']),

        async refreshSelectedTemplates() {
            if (this.tributeVideo.id) {
                var selectedTemps = await this.getSelectedTemplates(this.tributeVideo.id);
                if (selectedTemps.templates) {
                    this.updateTributeVideoSelectedTemplates(selectedTemps.templates);
                }
            }
        },

        openModal() {
            this.$emit('pause-preview');
            this.isModalOpen = true;
        },
        handleWheel(event) {
            // How much a user needs to scroll before it triggers the expanding behavior
            const wheelBuffer = 50;
            // Access event.deltaY to get the scroll direction and amount
            const direction = event.deltaY > 0 ? 'down' : event.deltaY < 0 ? 'up' : '';
            const distance = Math.abs(event.deltaY);
            // console.log('Mousewheel:', { direction, distance, y: event.deltaY, event});
            // container that overflows the expanded content. Will only trigger a collapse when this is at the top
            const slideContainer = document.querySelector('.templates-wrapper');
            // Implement your custom logic here
            if (distance > wheelBuffer) {
                if (direction === 'down' && !this.isExpanded) {
                    this.toggleExpandedState();
                }

                if (direction === 'up' && this.isExpanded && slideContainer.scrollTop < 5) {
                    // ensure slides-container is at the top before collapsing
                    // console.log(slideContainer);
                    this.toggleExpandedState();
                }
            }
        },
        openPreviewModal(template) {
            this.selectedTemplateForPreview = template;
            this.$emit('pause-preview');
            this.isPreviewModalOpen = true;
        },

        closeModal() {
            this.isModalOpen = false;
        },

        async updateTheme({ modifications, font }) {
            this.closeModal();
            if (Object.keys(modifications).length > 0 || font) {
                const data = await this.selectTemplateWithMods(
                    this.tributeVideo.id,
                    this.selectedTemplate.id,
                    modifications,
                );
                return data;
            }
        },
        handlePreviewSave() {
            const selectedTemplate = this.filteredTemplates[this.selectedTemplateIndex];
            if (selectedTemplate) {
                this.quickSelectTemplate(this.tributeVideo.id, selectedTemplate.id);
                this.isPreviewModalOpen = true;
            } else {
                console.warn('No template selected');
            }
        },

        updateModificationIds(newModIds, currentModifications) {
            const result = [];

            currentModifications.forEach(mod => {
                const thisMod = newModIds.find(newMod => newMod.name === mod.name);

                if (thisMod) {
                    result.push({
                        ...mod,
                        key: thisMod.creatomateId,
                    });
                }
            });
            return result;
        },

        // TODO Format templateElements when mounted
        initializeModifications(templateElements, templateModifications) {
            this.modifications = templateElements
                .filter(
                    element =>
                        element.name &&
                        element.name !== 'MainPicture' &&
                        element.name !== 'dash' &&
                        element.name !== 'Quote' &&
                        element.name !== 'MainPictire',
                )
                .map(element => ({
                    key: element.creatomateId,
                    name: element.name,
                    value: templateModifications[element.creatomateId] || '',
                }));
        },
        formatModsForSaving(changedMods) {
            const mods = changedMods.map(obj => {
                const { name, ...rest } = obj;
                return rest;
            });
            return mods.reduce((acc, obj) => {
                const key = obj.key;
                const value = obj.value;
                acc[key] = value;
                return acc;
            }, {});
        },

        async quickSelectTemplate(tributeVideoId, templateId) {
            try {
                this.loading = true;

                if (!tributeVideoId) throw new Error('Invalid tribute video id');

                if (!templateId) throw new Error('Invalid template id');

                const modsChanged = this.hasCustomModifications(this.defaultModifications, this.modifications);
                const selectedTemplateDetails = await this.api.getTemplate(templateId);
                const tempMods = this.updateModificationIds(
                    selectedTemplateDetails.data.dynamicElements,
                    this.modifications,
                );

                if (modsChanged) {
                    const mods = this.formatModsForSaving(tempMods);
                    const resp = await this.api.selectTemplate(tributeVideoId, templateId, mods);
                    if (resp.data) {
                        this.selectedTemplate = resp.data;
                        this.updateTributeVideoSelectedTemplates([resp.data]);
                        this.modifications = tempMods;
                    }
                } else {
                    const resp = await this.api.selectTemplate(tributeVideoId, templateId);
                    if (resp.data) {
                        this.selectedTemplate = resp.data;
                        this.updateTributeVideoSelectedTemplates([resp.data]);
                        this.modifications = tempMods;
                    }
                }
                await this.refreshSmallPreview(tributeVideoId);

                this.showSnackbar({ message: 'Tribute Theme Updated.' });
                this.$emit('refresh-preview');
            } catch (error) {
                this.showSnackbar({ message: 'Unable to save theme.' });
            } finally {
                this.loading = false;
            }
        },

        async selectTemplateWithMods(tributeVideoId, templateId, mods = null) {
            try {
                this.loading = true;

                if (!tributeVideoId) throw new Error('Invalid tribute video id');

                if (!templateId) throw new Error('Invalid template id');

                var resp = await this.api.selectTemplate(tributeVideoId, templateId, mods);
                if (resp.data) {
                    this.selectedTemplate = resp.data;
                }

                await this.refreshSmallPreview(tributeVideoId);

                this.showSnackbar({ message: 'Tribute Theme Updated.' });
                this.$emit('refresh-preview');
                return;
            } catch {
                this.showSnackbar({ message: 'An Error Occurred - Please Try again Later' });
            } finally {
                this.loading = false;
            }
        },

        async refreshSmallPreview(tributeId) {
            var resp = await this.videoApi.generatePreviewJson(tributeId);

            if (resp.data.creatomateJson) {
                const creatomateJson = JSON.parse(resp.data.creatomateJson);

                if (!creatomateJson.source) throw new Error('Error parsing preview source');

                this.previewSource = JSON.stringify(creatomateJson.source);

                if (creatomateJson.modifications) {
                    this.previewMods = JSON.stringify(creatomateJson.modifications);
                }

                const sourceObj = JSON.parse(this.previewSource);
                this.templateFont = this.getCurrentFont(sourceObj.fonts);
            }
        },

        async previewTemplate(template) {
            this.$emit('pause-preview');
            this.selectedTemplateForPreview = template;
            this.isPreviewModalOpen = true;
        },

        toggleHeight() {
            this.toggleExpandedState();
        },

        getCurrentFont(fonts) {
            if (!Array.isArray(fonts) || fonts.length === 0) {
                return {};
            }

            for (let font of fonts) {
                if (typeof font.source === 'string' && font.source.includes('fonts.gstatic.com')) {
                    return font;
                } else if (font.weight === 700) {
                    return font;
                }
            }
            return fonts[0];
        },
        async refreshSelectedTemplate() {
            const res = this.api.getSelected(this.tributeVideo.id);
            return res.data.templates[0];
        },
        formatTemplate(rawTemplate) {
            const { id, cosmosId, duration, dynamicElements, staffFavorite, title, url } = rawTemplate;
            return { id, cosmosId, duration, dynamicElements, staffFavorite, title, url };
        },

        async loadMoreTemplates() {
            if (this.templates.length >= this.availableTemplates || this.loading) return;

            try {
                this.pageNumber += 1;

                const moreTemplates = await this.api.getTemplates({
                    pageNumber: this.pageNumber,
                    pageSize: this.pageSize,
                });

                if (moreTemplates && moreTemplates.data && moreTemplates.data.templates) {
                    const newTemplates = moreTemplates.data.templates.map(t => this.formatTemplate(t));

                    this.templates = [...this.templates, ...newTemplates];
                }
            } catch (error) {
                this.showSnackbar({ message: 'Unable to load all theme templates' });
            }
        },
        handleIntersection(entries) {
            const entry = entries[0];
            if (entry.isIntersecting) {
                if (this.templates.length < this.availableTemplates) {
                    this.loadMoreTemplates();
                } else {
                    if (this.observer && this.$refs.observerSentinel) {
                        this.observer.disconnect(this.$refs.observerSentinel);
                    }
                }
            }
        },
        hasCustomModifications(obj, array) {
            for (let item of array) {
                const { name } = item;

                if (obj.hasOwnProperty(name) && obj[name] !== item.value) {
                    return true;
                }
            }

            return false;
        },
        changeTemplate(direction) {
            const templateCount = this.filteredTemplates.length;
            if (templateCount === 0) return;

            if (direction === 'prev') {
                this.selectedTemplateIndex = (this.selectedTemplateIndex - 1 + templateCount) % templateCount;
            } else if (direction === 'next') {
                this.selectedTemplateIndex = (this.selectedTemplateIndex + 1) % templateCount;
            }
        },
        setDefaultModifications(tributeVideo) {
            function formatDateToYear(dateString) {
                if (!dateString) return null;
                const date = new Date(dateString);
                const thisDate = date.getFullYear();
                return thisDate.toString();
            }

            const { birthDate, deathDate, firstName, lastName, mainPhotoUrl } = tributeVideo;

            this.mainPhotoSource = mainPhotoUrl;

            this.defaultModifications = {
                FirstName: firstName,
                LastName: lastName,
                BirthDate: formatDateToYear(birthDate),
                DeathDate: formatDateToYear(deathDate),
            };
        },
    },
    async mounted() {
        const { __raw } = await this.$auth.getIdTokenClaims();
        this.token = __raw;
        this.api = TributeThemeService(this.token);
        this.videoApi = TributeVideoService(this.token);

        // TODO improve error handling
        if (this.eventId && this.tributeVideo.id) {
            const [selectedResponse, allResponse] = await Promise.all([
                this.api.getSelected(this.tributeVideo.id),
                this.api.getTemplates({ pageNumber: this.pageNumber, pageSize: 24 }),
            ]);

            this.availableTemplates = allResponse.data.total;

            console.log(allResponse, 'templates');

            this.selectedTemplate = selectedResponse.data.templates[0];
            const selectedTemplateDetails = await this.api.getTemplate(this.selectedTemplate.id);

            await this.refreshSmallPreview(this.tributeVideo.id);

            this.initializeModifications(
                selectedTemplateDetails.data.dynamicElements,
                this.selectedTemplate.modifications,
            );

            this.templates = allResponse.data.templates.map(template => {
                return this.formatTemplate(template);
            });

            this.$store.dispatch('tributeVideo/updateTributeVideoSelectedTemplates', [this.selectedtemplate]);

            this.setDefaultModifications(this.$props.tributeVideo);

            this.loading = false;
        }

        if ('IntersectionObserver' in window) {
            this.observer = new IntersectionObserver(this.handleIntersection, {
                root: this.$refs.templatesWrapper,
                threshold: 0,
            });
        }
    },
    beforeDestroy() {
        if (this.observer) {
            this.observer.disconnect();
        }
    },
    data() {
        return {
            api: null,
            videoApi: null,
            token: null,
            selectedTemplateIndex: 0,
            templates: [],
            selectedTemplate: [],
            showEditModal: false,
            showPreviewModal: false,
            previewSource: '',
            previewMods: '',
            templateFont: {},
            modifications: [],
            defaultModifications: [],
            loading: true,
            isModalOpen: false,
            isPreviewModalOpen: false,
            selectedTemplateForPreview: null,
            pageSize: 24,
            pageNumber: 0,
            availableTemplates: 0,
            mainPhotoSource: '',
            observer: null,
        };
    },
};
</script>

<style lang="scss" scoped>
.manage-slides {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    transition: height 0.8s;
}

.theme-placeholder {
    width: 360px;
    height: 220px;
    border-radius: 6px;
    position: relative;
    gap: 8px;
    padding: 16px;
    flex-shrink: 0;
    display: inline-block;
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px, #ddd 400px);
    background-repeat: repeat-x;
    animation: shimmer 1s infinite linear;
}

.action-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 38px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.header-text.expanded {
    display: block;
    margin: 0;
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.025em;
    text-align: center;
    text-decoration-skip-ink: none;
    color: rgba(107, 114, 128, 1);
    text-transform: uppercase;
}

.header-text {
    display: none;
}

.icon-button {
    width: 38px;
    height: 38px;
    background: white;
    border: 1px solid #d1d5db;
    display: flex;
    align-items: center;
    justify-content: center;

    &.rounded {
        border-radius: 8px !important;
    }

    &.wide {
        width: initial;
        padding: 0 10px;
        font-weight: 500;
    }
    &:hover {
        background-color: $light-gray;
    }
}

.divider {
    display: block;
    width: 2px;
    height: 84px;
    margin: auto 0;
    border: 1px solid #d1d5db;
}

.divider-wrapper {
    display: block;
    align-items: center;
    width: 100%;
    padding-top: 80px;
}

.divider.expanded {
    display: none;
}

.divider-wrapper.expanded {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 0;
    height: 20px;
}

.slides-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 16px;
    overflow-x: auto;
    overflow-y: hidden;
    padding-top: 8px;
    height: 100%;
    max-height: 252px;
    transition: height 0.8s;
    scroll-snap-type: x mandatory;
}

.slides-container.expanded {
    flex-wrap: wrap;
    overflow-x: hidden;
    min-height: 0;
    max-height: 100%;
    align-content: flex-start;
}

.slides-container.expanded .templates-wrapper .slide {
    width: 100%;
    height: auto;
    flex-shrink: 1;
    aspect-ratio: 8 / 5;
}

.slides-container.expanded .selected-template-wrapper .slide {
    width: 100%;
    height: auto;
    flex-shrink: 1;
    aspect-ratio: 8 / 5;
}

.templates-wrapper,
.selected-template-wrapper {
    display: flex;
    gap: 16px;
    flex-wrap: nowrap;
    height: 250px;
    flex: 1 1 auto;
    overflow-x: visible;
}

.slides-container:not(.expanded) {
    overflow-x: auto;
}

.templates-wrapper.expanded {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    overflow-y: auto;
    overflow-x: hidden;
    height: auto;
    max-height: 450px;
    width: 100%;
    box-sizing: border-box;
    padding-right: 5px;
    padding-bottom: 30px;
}

.right-buttons {
    display: flex;
    width: 260px;
    justify-content: flex-end;
    border-radius: 6px;
    gap: 8px;
    box-shadow: none;
}

.selected-template-wrapper.expanded {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    overflow-y: auto;
    overflow-x: hidden;
    height: auto;
    max-height: 300px;
    width: 100%;
    box-sizing: border-box;
    padding-right: 5px;
}
</style>
