var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{staticClass:"explorer-header"},[_c('div',{staticClass:"header-left"},[_vm._t("header-left",function(){return [_c('span',{staticClass:"explorer-title"},[_vm._v("Explore our music list")])]})],2),_c('div',{staticClass:"header-right"},[_vm._t("header-right",function(){return [_c('div',[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"deep-orange"}}):_vm._e()],1),_c('v-text-field',{staticClass:"search-field",attrs:{"loading":_vm.loading,"dense":"","outlined":"","placeholder":"Search Music","debounce":250,"clearable":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-magnifying-glass"}})]},proxy:true}]),model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}}),_c('v-select',{ref:"genreSelect",staticClass:"explore-header-field",attrs:{"dense":"","placeholder":"Genres","outlined":"","clearable":"","items":_vm.genres,"item-text":"name","item-value":"id","multiple":""},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.smAndDown)?{key:"prepend-item",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Filter by Genre ")])],1)],1)]},proxy:true}:null,(_vm.$vuetify.breakpoint.smAndDown)?{key:"append-item",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-btn',{attrs:{"color":"orange"}},[_vm._v("Apply Filter")])],1)],1)]},proxy:true}:null,{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.filters.selectedGenres.length - 1)+") ")]):_vm._e()]}}],null,true),model:{value:(_vm.filters.selectedGenres),callback:function ($$v) {_vm.$set(_vm.filters, "selectedGenres", $$v)},expression:"filters.selectedGenres"}}),_c('v-select',{ref:"keywordSelect",staticClass:"explore-header-field",attrs:{"dense":"","outlined":"","placeholder":"Keywords","clearable":"","items":_vm.keywords,"item-text":"name","item-value":"id","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.filters.selectedKeywords.length - 1)+") ")]):_vm._e()]}}]),model:{value:(_vm.filters.selectedKeywords),callback:function ($$v) {_vm.$set(_vm.filters, "selectedKeywords", $$v)},expression:"filters.selectedKeywords"}}),(_vm.token)?_c('TributeMusicUploader',{ref:"uploader",attrs:{"hideButton":true,"token":_vm.token,"funeralHomeId":_vm.$props.funeralHomeId},on:{"upload-success":_vm.onAddCustomMusic},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var open = ref.open;
return [_c('v-btn',{staticClass:"custom-upload",attrs:{"outlined":""},on:{"click":open}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-upload ")]),_vm._v(" Upload your own ")],1)]}}],null,false,3308821905)}):_vm._e()]})],2)]),_c('main',{staticClass:"track-list py-2"},[_vm._l((_vm.songs),function(song){return [_c('MusicTrack',{key:song.id,attrs:{"song":song,"isSelected":_vm.selectedSongsIds.includes(song.id),"token":_vm.token}})]}),(!_vm.loading && !_vm.songs.length)?_c('div',{staticClass:"no-results"},[_c('div',[_vm._v("No results to display.")])]):(_vm.loading)?_c('div',{staticClass:"no-results"},[_c('div',[_vm._v("Loading")])]):_vm._e()],2),_c('div',{staticClass:"pagination"},[_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":6},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }