import ApiService from './api.service';

const AdminToolService = token => {
    const api = ApiService.createApiInstance(token);

    return {
        dumpRedisDB() {
            return api.delete('/admin-tools/dump-redis-cache');
        },
    };
};

export default AdminToolService;
