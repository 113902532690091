<template>
    <div class="tribute-view">
        <TributeStepper :currentStep="currentStep" @change-step="val => handleChangeStep(val)" />

        <div class="step-content">
            <div :class="['middle-content', { expanded: tributeEditorExpanded }]" v-if="currentStep > 1">
                <div class="action-column">
                    <v-btn class="text-normal" @click="handleChangeStep(currentStep - 1)">
                        <v-icon left> mdi-chevron-left </v-icon>
                        Prev
                    </v-btn>
                </div>
                <div class="preview-player">
                    <TributeRenderOverlay
                        v-if="currentStep >= 5 && showTributeOverlay"
                        @playPreview="playPreview"
                        @pausePreview="pausePreview"
                    ></TributeRenderOverlay>
                    <template v-if="standardRender && !tributeIsRendering">
                        <video
                            :src="standardRender.url"
                            controls
                            disablepictureinpicture
                            controlslist="nofullscreen noplaybackrate nodownload"
                            class="final-render"
                        ></video>
                    </template>
                    <template v-else>
                        <CreatomatePreview
                            ref="creatomatePreview"
                            :source="previewSource"
                            :modifications="previewMods"
                        />
                    </template>
                </div>
                <v-btn
                    v-if="tributeEditorExpanded"
                    @click="togglePreview"
                    color="white"
                    class="text-normal"
                    style="max-width: 500px; width: 30%"
                >
                    <font-awesome-icon icon="fa-regular fa-play-circle" class="fa-lg mr-2 play-icon" />
                    Preview Video
                </v-btn>
                <div class="action-column">
                    <v-btn
                        class="next-btn text-normal"
                        @click="handleChangeStep(currentStep + 1)"
                        v-show="currentStep < 5"
                        :disabled="currentStep >= 5"
                    >
                        Next
                        <v-icon right> mdi-chevron-right </v-icon>
                    </v-btn>
                </div>
            </div>

            <TributeSetup
                v-if="currentStep === 1 && $route.params.slug"
                :serviceSlug="$route.params.slug"
                :tributeVideo="tributeVideo"
                @tribute-video-created="data => handleTributeCreated(data)"
                @next-step="handleChangeStep(currentStep + 1)"
            />

            <ResizableWorkspace v-if="currentStep === 2 && tributeVideo.eventId">
                <ManagePhotos
                    :eventId="tributeVideo.eventId"
                    :tributeVideo="tributeVideo"
                    @pause-preview="pausePreview"
                    @refresh-preview="tryGeneratePreviewJson(tributeVideo.id)"
                />
            </ResizableWorkspace>

            <ResizableWorkspace v-if="currentStep === 3 && tributeVideo.eventId && tributeVideo.id">
                <ManageThemes
                    :eventId="tributeVideo.eventId"
                    :tributeVideo="tributeVideo"
                    @refresh-preview="tryGeneratePreviewJson(tributeVideo.id)"
                />
            </ResizableWorkspace>

            <ResizableWorkspace v-if="currentStep === 4 && tributeVideo">
                <ManageMusic
                    :eventId="tributeVideo.eventId"
                    @refresh-preview="tryGeneratePreviewJson(tributeVideo.id)"
                    :tributeVideo="tributeVideo"
                />
            </ResizableWorkspace>

            <ResizableWorkspace v-if="currentStep === 5 && tributeVideo.id">
                <StepCreate :tributeVideo="tributeVideo" ref="createStep" @render-started="onRenderStarted" />
            </ResizableWorkspace>
        </div>

        <MiniMusicPlayer :token="token" v-if="token" />
    </div>
</template>

<script>
import initApiServices from '@/services/ApiInitializer';
import { mapActions, mapGetters } from 'vuex';

import TributeStepper from '@/components/Tribute/TributeStepper.vue';
import TributeSetup from '@/components/Tribute/TributeSetup.vue';
import ManagePhotos from '@/components/Tribute/ManagePhotos.vue';
import ManageThemes from '@/components/Tribute/ManageThemes.vue';
import MiniMusicPlayer from '@/components/Tribute/Music/MiniMusicPlayer.vue';
import CreatomatePreview from '@/components/Tribute/Themes/CreatomatePreview.vue';
import ManageMusic from '@/components/Tribute/ManageMusic.vue';
import StepCreate from '@/components/Tribute/StepCreate.vue';
import ResizableWorkspace from '@/components/Tribute/Layout/ResizableWorkspace.vue';
import TributeRenderOverlay from '@/components/Tribute/RenderOverlay/RenderOverlay.vue';
const renderStatusDict = ['Planned', 'Rendering', 'Succeeded', 'Failed', 'Deleted'];
export default {
    name: 'TributeView',
    metaInfo: {
        title: 'Tribute Video',
    },
    components: {
        TributeStepper,
        TributeSetup,
        ManagePhotos,
        CreatomatePreview,
        ManageThemes,
        ManageMusic,
        MiniMusicPlayer,
        StepCreate,
        ResizableWorkspace,
        TributeRenderOverlay,
    },
    provide() {
        const tributeRender = {};

        Object.defineProperty(tributeRender, 'standardRender', {
            enumerable: true,
            get: () => this.standardRender,
        });
        return {
            tributeRender,
        };
    },
    computed: {
        showTributeOverlay() {
            // Show overlay if the following is true
            // we're on step 5
            // we've started and/or completed a render
            //
            if (this.currentStep >= 5) {
                if (this.$store.state.tributeVideo.overlayDismissed) {
                    return false;
                }
                if (this.tributeIsRendering) {
                    return true;
                }
                if (this.$store.state.tributeVideo.renderStarted && this.$store.state.tributeVideo.renderCompleted) {
                    return true;
                }
            }
            return false;
        },
        tributeEditorExpanded() {
            return this.$store.state.tributeEditor.expanded;
        },
        tributeVideo: {
            get() {
                return this.$store.state.tributeVideo;
            },
            set(val) {
                this.$store.dispatch('tributeVideo/updateTributeVideo', {
                    ...val,
                });
            },
        },
        ...mapGetters('tributeVideo', {
            minimumRequirementsMet: 'minimumCreateRequirementsMet',
            tributeIsRendering: 'tributeIsRendering',
        }),
    },
    data() {
        return {
            currentStep: 1,
            // tributeVideo: null,
            standardRender: null,
            copyrightSafeRender: null,
            apiService: null,
            token: null,
            previewSource: null,
            previewMods: null,
            editMode: false,
        };
    },
    methods: {
        ...mapActions(['showSnackbar', 'block']),
        ...mapActions('tributeEditor', ['toggleExpandedState']),
        handleChangeStep(val) {
            console.log('handling change step', val);
            if (val < 0) return;
            if (val > 5) return;

            this.currentStep = val;
            // Pause preview player if currently rendering and going back into the 5th section
            if (this.currentStep === 5) {
                if (this.tributeIsRendering) {
                    this.pausePreview();
                }
            }
        },
        togglePreview() {
            this.toggleExpandedState();
        },
        onRenderStarted() {
            // Do stuff when render starts
            this.pausePreview();
        },
        playPreview() {
            const previewInstance = this.$refs.creatomatePreview;
            if (previewInstance && previewInstance.playVideo) {
                previewInstance.playVideo();
            }
        },
        pausePreview() {
            const previewInstance = this.$refs.creatomatePreview;
            if (previewInstance && previewInstance.pauseVideo) {
                previewInstance.pauseVideo();
            }
        },
        createTributeVideo() {
            // If not on the last step take them there first
            // then after delay try and trigger the render
            if (this.currentStep !== 5) {
                this.currentStep = 5;
            }
            this.$nextTick(() => {
                this.$refs.createStep.submitTributeRender();
            });
        },
        handleTributeCreated(data) {
            this.tributeVideo = data;

            if (this.tributeVideo.eventId) {
                this.handleChangeStep(this.currentStep + 1);
            }

            if (this.tributeVideo.id) {
                this.tryGeneratePreviewJson(this.tributeVideo.id);
            }

            this.tryFetchTributeVideo(this.$route.params.slug);
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        async tryFetchTributeVideo(slug) {
            try {
                this.block(true);
                const resp = await this.apiService.tributeVideo.getTributeVideoByServiceSlug(slug);
                if (resp.data) {
                    this.tributeVideo = resp.data.tributeVideo;
                    this.standardRender = resp.data.standardRender;
                    this.copyrightSafeRender = resp.data.copyrightSafeRender;
                    if (this.standardRender) {
                        // console.log('RENDER READY', this.standardRender);
                        if (this.currentStep !== 5) {
                            this.currentStep = 5;
                        }
                    } else {
                        this.currentStep = 2;
                    }
                    this.$store.dispatch('tributeVideo/updateTributeVideo', {
                        ...this.tributeVideo,
                    });
                }
            } catch (error) {
                console.error('Error fetching tribute video', error);
            } finally {
                this.block(false);
            }
        },
        async getSelectedPhotos() {
            let payload = {
                reversed: false,
                pageNumber: 0,
                pageSize: 25,
            };

            if (!this.tributeVideo?.eventId) {
                return;
            }
            const { data } = await this.apiService.tributePhoto.getPhotos(this.tributeVideo.eventId, payload);
            if (data?.total > 0) {
                this.$store.dispatch('tributeVideo/updateTributeVideoSelectedPhotos', data.photos);
            }
        },
        async getSelectedThemes() {
            if (!this.tributeVideo?.id) {
                return;
            }
            const { data } = await this.apiService.tributeTemplate.getSelected(this.tributeVideo.id);
            if (data.templates?.length) {
                this.$store.dispatch('tributeVideo/updateTributeVideoSelectedTemplates', data.templates);
            }
        },
        async getSelectedSongs() {
            if (!this.tributeVideo?.id) {
                return;
            }
            const { data: songs } = await this.apiService.tributeSong.getSelected(this.tributeVideo.id);
            if (songs?.length) {
                this.$store.dispatch('tributeVideo/updateTributeVideoSelectedSongs', songs);
            }
        },
        async tryGeneratePreviewJson(tributeId) {
            try {
                if (!tributeId) {
                    throw new Error('Invalid tribute video id');
                }

                var resp = await this.apiService.tributeVideo.generatePreviewJson(tributeId);
                // TODO: confirm this is the duration duration
                if (resp.data.duration) {
                    console.log('duration updated on tribute store');
                    // store the duration on the tribute video store
                    this.$store.dispatch('tributeVideo/updateTributeVideo', { totalDuration: resp.data.duration });
                }
                if (resp.data.creatomateJson) {
                    const creatomateJson = JSON.parse(resp.data.creatomateJson);

                    if (!creatomateJson.source) throw new Error('Error parsing preview source');

                    this.previewSource = JSON.stringify(creatomateJson.source);

                    if (creatomateJson.modifications) {
                        this.previewMods = JSON.stringify(creatomateJson.modifications);
                    }
                }
            } catch (error) {
                console.log(error, 'error');
            }
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.apiService = initApiServices(this.token);

        await this.tryFetchTributeVideo(this.$route.params.slug);

        console.log(this.tributeVideo, 'tributeVideo');
        // TODO: setup default template so that preview is ready @ step 2
        if (this.tributeVideo?.id) {
            this.tryGeneratePreviewJson(this.tributeVideo.id);
            // Fetch selected Photos, Music, & Themes
            // Photos
            this.getSelectedPhotos();
            // Themes
            this.getSelectedThemes();
            // Music
            this.getSelectedSongs();
        }
    },
    sockets: {
        async NotifyTributeRender(data) {
            if (this.tributeVideo.id == data.tributeVideoId) {
                switch (renderStatusDict[data.status]) {
                    // TODO: See if Planned & Rendering should set the renderOverlay...
                    // Usecase: starting a render in a different tab or by another user?
                    case 'Planned':
                        break;
                    case 'Rendering':
                        break;
                    case 'Succeeded':
                        this.$nextTick(async () => {
                            // update the tributestore
                            await this.tryFetchTributeVideo(this.$route.params.slug);
                            // We've recieved the socket event, and we've fetched the latest json
                            // So if we have a standard render and url... can we safely assume that it has finished?
                            if (this.standardRender?.url) {
                                this.$store.dispatch('tributeVideo/updateTributeVideo', {
                                    renderCompleted: new Date().toISOString(),
                                });
                            }
                        });
                        break;
                    case 'Failed':
                    case 'Delete':
                        // Render failed or was deleted, we should notify user and remove render overlay
                        this.showSnackbar({
                            message: 'Rendering the video has failed. Please wait and try again.',
                            color: 'error',
                        });
                        this.$store.dispatch('tributeVideo/updateTributeVideo', {
                            renderStarted: null,
                            renderCompleted: null,
                            overlayDismissed: true,
                        });

                        break;
                }
            }
        },
        async NotifyTributeJsonRefreshed(data) {
            console.log('json refreshed heard', data);

            if (this.tributeVideo?.id && data.id == this.tributeVideo.id) {
                this.tryGeneratePreviewJson(this.tributeVideo.id);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.tribute-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    background-color: #f3f4f6;
    overflow-y: auto;
}

.step-content {
    width: 100%;
    max-width: 800px;
    margin-top: 82px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    height: auto;
    transition: height 0.8s ease;
    flex-grow: 1;
}

.preview-player {
    max-width: 800px;
    width: auto;
    background-color: #ffffff;
    padding: 12px;
    border-radius: 8px;
    top: 0;
    position: relative;
    transition: top 400ms ease-in-out;
    flex: 1;
    aspect-ratio: 16 / 9;
    &.expanded {
        top: -50vh;
    }
}
.middle-content {
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: calc(100vh - 34vh - 140px);
    .preview-player {
        align-self: center;
        .preview-component-container {
            margin: 0 auto;
            max-height: min(49vh, 430px);
        }
    }
    .action-column {
        min-width: 120px;
        text-align: center;
        align-content: center;
    }
    &.expanded {
        height: fit-content;
        margin-top: -12px;

        .preview-player {
            height: 0;
            overflow: hidden;
            display: none;
        }
    }
    @include mobile() {
        &:not(.expanded) {
            flex-direction: row;
            flex-wrap: wrap;

            .preview-player {
                order: 0;
                flex: 1 1 100%;
            }
            .action-column {
                order: 1;
                flex: 1 0 50%;
            }
        }
    }
}
.play-icon {
    color: $primary-grey;
}
.next-btn {
    background-color: $primary-orange !important;
    color: white !important;
}
.final-render {
    width: 100%;
}
</style>
