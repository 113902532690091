<template>
    <div class="live-preview-component-container">
        <div class="preview-container" :class="loading ? 'hidden-preview' : ''" ref="previewContainer"></div>
        <div class="preview-overlay" :class="loading ? '' : 'hidden-preview'">
            <div v-if="loadError" class="d-flex flex-column align-items-center">
                <font-awesome-icon style="font-size: 2rem" icon="fa-regular fa-cloud-exclamation"></font-awesome-icon>
                <p class="mt-2">Error loading preview.</p>
            </div>
            <div v-else-if="!template" class="d-flex flex-column align-items-center">
                <font-awesome-icon style="font-size: 2rem" icon="fa-regular fa-cloud-exclamation"></font-awesome-icon>
                <p class="mt-2">Preview source not found.</p>
            </div>
            <div v-else class="d-flex flex-column align-items-center">
                <v-progress-circular indeterminate></v-progress-circular>
                <p class="mt-2">Loading preview...</p>
            </div>
        </div>
        <div>
            <button v-if="!isPlaying" @click="playVideo">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.19019 5.87695V20.5734L20.7374 13.2252L9.19019 5.87695Z" fill="white" />
                </svg>
            </button>

            <button v-else @click="pauseVideo">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="7" y="5" width="4" height="16" fill="white" />
                    <rect x="15" y="5" width="4" height="16" fill="white" />
                </svg>
            </button>
        </div>
    </div>
</template>
<script>
import { Preview } from '@creatomate/preview';

export default {
    data() {
        return {
            preview: null,
            loading: true,
            loadError: false,
            isPlaying: false,
        };
    },
    props: {
        template: {
            type: String,
            default: false,
        },
        FirstName: {
            type: String,
            required: true,
        },
        LastName: {
            type: String,
            required: true,
        },
        BirthDate: {
            type: String,
            required: true,
        },
        DeathDate: {
            type: String,
            required: true,
        },
        photo: {
            type: String,
            required: true,
        },
    },
    watch: {
        async template (newTemplate) {
            this.isPlaying = false;
            this.initPreview(newTemplate);
        },
        async FirstName(newValue) {
            this.isPlaying = false;

            if (this.preview) {
                await this.preview.applyModifications({
                    FirstName: newValue,
                });
                await this.ensureElementVisibility(this.preview, 'FirstName', 1.5);
            }
        },
        async LastName(newValue) {
            this.isPlaying = false;

            if (this.preview) {
                await this.preview.applyModifications({
                    LastName: newValue,
                });
                await this.ensureElementVisibility(this.preview, 'LastName', 1.5);
            }
        },
        async BirthDate(newValue) {
            this.isPlaying = false;

            if (this.preview) {
                await this.preview.applyModifications({
                    BirthDate: newValue,
                });
                await this.ensureElementVisibility(this.preview, 'BirthDate', 1.5);
            }

        },
        async DeathDate(newValue) {
            this.isPlaying = false;

            if (this.preview) {
                await this.preview.applyModifications({
                    DeathDate: newValue,
                });
                await this.ensureElementVisibility(this.preview, 'DeathDate', 1.5);
            }
        },
    },

    mounted() {
        if (this.template) {
            this.initPreview();
        }
    },
    methods: {
        reset() {
            this.loading = true;
        },
        async playVideo() {
            this.isPlaying = true;
            if (this.preview) {
                await this.preview.play();
            }
        },
        async pauseVideo() {
            this.isPlaying = false;
            if (this.preview) {
                await this.preview.pause();
            }
        },
        async ensureElementVisibility(preview, elementName, addTime) {
            const element = preview.getElements().find((element) => element.source.name === elementName);
            if (element) {
                await preview.setTime(element.globalTime + addTime);
            }
        },

        initPreview() {
            this.loading = true;

            const el = this.$refs.previewContainer;

            if (el) {
                const preview = new Preview(el, 'interactive', process.env.VUE_APP_CREATOMATE_KEY);
                this.preview = preview;

                preview.onReady = async () => {
                    try {

                        await preview.loadTemplate(this.$props.template);

                        await this.preview.setZoom('fixed', 0.5)
                        await this.preview.applyModifications({
                            FirstName: this.$props.FirstName,
                            LastName: this.$props.LastName,
                            BirthDate: this.$props.BirthDate,
                            DeathDate: this.$props.DeathDate,
                            MainPicture: this.$props.photo,
                        });

                        this.loading = false;

                    } catch (error) {
                        this.loadError = true;
                    } finally {
                        await this.preview.play();
                        this.isPlaying = true;
                    }
                };
            }
        },
    },
};
</script>

<style lang="scss">
.live-preview-component-container {
    position: relative;
    aspect-ratio: 16/9;
    background-color: black;
    

    .hidden-preview {
        opacity: 0;
        z-index: -2;
    }
}

.preview-container {
    aspect-ratio: 16/9;
    opacity: 1;
    transition: 0.5s;
}

.preview-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #00000000;
    color: white;
}
</style>
