<template>
    <div class="slide" @click.stop="onSelect">
        <div class="timestamp-bar" :style="timestampStyle">
            <svg
                class="camera-icon"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10 6.66667L13.0352 5.14907C13.4785 4.92744 14 5.24977 14 5.74536V10.2546C14 10.7502 13.4785 11.0726 13.0352 10.8509L10 9.33333M3.33333 12H8.66667C9.40305 12 10 11.403 10 10.6667V5.33333C10 4.59695 9.40305 4 8.66667 4H3.33333C2.59695 4 2 4.59695 2 5.33333V10.6667C2 11.403 2.59695 12 3.33333 12Z"
                    stroke="#6B7280"
                    stroke-width="1.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
            <span class="ml-2"> {{ formatTimeStamp(duration) }}</span>
        </div>

        <img :src="thumbnail" class="slide-image" />

        <div v-if="staffFavorite" class="staff-picked" :style="staffPickedStyle">
            <CrownIcon />
            <span>STAFF PICKED</span>
        </div>

        <div class="template-overlay">
            <div class="template-title">{{ title }}</div>
            <div class="button-group">
                <button
                    v-if="!isSelected"
                    :disabled="loading"
                    @click.stop="onSelect"
                    class="template-button white-button"
                >
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10 3.83398V17.1673M16.6667 10.5007L3.33337 10.5007"
                            stroke="#6B7280"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>
                <button v-if="!isSelected" @click.stop="onPreview" class="template-button white-button">
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.5 10.4993C12.5 11.8801 11.3808 12.9993 10 12.9993C8.61933 12.9993 7.50004 11.8801 7.50004 10.4993C7.50004 9.11864 8.61933 7.99935 10 7.99935C11.3808 7.99935 12.5 9.11864 12.5 10.4993Z"
                            stroke="#6B7280"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M2.04858 10.4993C3.11048 7.11841 6.26905 4.66602 10.0004 4.66602C13.7318 4.66602 16.8904 7.11844 17.9522 10.4994C16.8904 13.8803 13.7318 16.3327 10.0004 16.3327C6.26905 16.3327 3.11046 13.8803 2.04858 10.4993Z"
                            stroke="#6B7280"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>

                <div class="button-group">
                    <button v-if="isSelected" class="template-button orange-button">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M4.16663 11.334L7.49996 14.6673L15.8333 6.33398"
                                stroke="#F97316"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </button>
                    <button v-if="isSelected" @click.stop="onEdit(template)" class="template-button white-button">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16.8898 3.61019L17.5969 2.90309V2.90309L16.8898 3.61019ZM5.41667 18.0296V19.0296C5.68188 19.0296 5.93624 18.9242 6.12377 18.7367L5.41667 18.0296ZM2.5 18.0296H1.5C1.5 18.5819 1.94772 19.0296 2.5 19.0296V18.0296ZM2.5 15.0537L1.79289 14.3466C1.60536 14.5341 1.5 14.7885 1.5 15.0537H2.5ZM14.6506 4.3173C15.0737 3.89423 15.7596 3.89423 16.1827 4.3173L17.5969 2.90309C16.3928 1.69897 14.4405 1.69897 13.2364 2.90309L14.6506 4.3173ZM16.1827 4.3173C16.6057 4.74037 16.6057 5.4263 16.1827 5.84937L17.5969 7.26358C18.801 6.05946 18.801 4.1072 17.5969 2.90309L16.1827 4.3173ZM16.1827 5.84937L4.70956 17.3225L6.12377 18.7367L17.5969 7.26358L16.1827 5.84937ZM5.41667 17.0296H2.5V19.0296H5.41667V17.0296ZM13.2364 2.90309L1.79289 14.3466L3.20711 15.7608L14.6506 4.3173L13.2364 2.90309ZM1.5 15.0537V18.0296H3.5V15.0537H1.5ZM11.9864 5.5673L14.9327 8.51358L16.3469 7.09937L13.4006 4.15309L11.9864 5.5673Z"
                                fill="#6B7280"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CrownIcon from '@/assets/icons/crownIcon.vue';
import moment from 'moment';

export default {
    name: 'ThemeCard',
    components: {
        CrownIcon,
    },
    props: {
        template: {
            type: Object,
            required: true,
        },
        isSelected: {
            type: Boolean,
            required: true,
        },
        onSelect: {
            type: Function,
            required: false,
        },
        onPreview: {
            type: Function,
            required: false,
        },
        onEdit: {
            type: Function,
            required: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        timestampStyle() {
            return {
                position: 'absolute',
                top: '8px',
                left: '8px',
                zIndex: 2,
            };
        },
        staffPickedStyle() {
            return {
                position: 'absolute',
                top: '8px',
                right: '8px',
                zIndex: 2,
            };
        },
        currentTemplate() {
            return this.template;
        },
        title() {
            return this.template.title;
        },
        duration() {
            return this.template.duration;
        },
        thumbnail() {
            const srcPath = this.template.url.split('/tribute-template-renders/')[1];
            const thumbNailMod = '/ik-thumbnail.jpg?tr=so-2';
            return `${process.env.VUE_APP_IMG_KIT_BASE}templates/${srcPath}${thumbNailMod}`;
        },
        staffFavorite() {
            return this.template.staffFavorite;
        },
    },
    methods: {
        formatTimeStamp(seconds) {
            const duration = moment.duration(seconds, 'seconds');
            const minutes = duration.minutes();
            const secs = duration.seconds();

            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(secs).padStart(2, '0');

            return `${formattedMinutes}:${formattedSeconds}`;
        },
    },
};
</script>

<style scoped>
.slide {
    width: 360px;
    height: 100%;
    max-height: 225px;
    border-radius: 12px;
    position: relative;
    transition: transform 0.2s, border 0.2s;
    flex-shrink: 0;
    display: inline-block;
    background: white;
    overflow: hidden;
    border: 1px solid #ccc;
}

.timestamp-bar {
    display: flex;
    align-items: center;
    width: 90px;
    height: 35px;
    background: white;
    border: 1px solid #d1d5db;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 6px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #6b7280;
    padding-right: 12px;
    padding-left: 12px;
    letter-spacing: 2.5%;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.staff-picked {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 133.14px;
    height: 35px;
    padding: 9px 8px 9px 4px;
    border-radius: 54px 54px 0px 54px;
    background: #f97316;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: white;
    border: 1px solid #9a3412;
}

.template-overlay {
    width: 100%;
    height: 54px;
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f9fafb;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;
}

.slide-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.template-title {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #374151;
}

.button-group {
    display: flex;
    gap: 8px;
}

.template-button {
    width: 38px;
    height: 38px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border: 1px solid #d1d5db;
}

.template-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.white-button {
    background: white;
    border: 1px solid #d1d5db;
}

.orange-button {
    background: #fff7ed;
    border: 1px solid #fdba74;
}
</style>
