<template>
    <div class="upload-page-wrapper">
        <animated-waves-background
            v-if="tribute"
            :startColor="customStartColor"
            :endColor="customEndColor"
            class="animated-bg-comp"
        />

        <div class="logo-wrap" v-if="logo">
            <img style="max-width: 100%; max-height: 100%" :src="logo" />
        </div>

        <!-- App redirect banner -->
        <!-- <MobileRedirectBanner v-if="os && displayRedirectBanner" :os="os" :appId="appId" /> -->

        <v-container fluid class="hero">
            <v-layout justify-center align-center>
                <v-flex class="card-wrap">
                    <div v-if="tribute" class="float-card elevation-12 m-4">
                        <v-row>
                            <v-col class="tribute-header">
                                <div id="tribute-avatar" class="d-flex align-center">
                                    <v-avatar class="mr-2" v-if="tributeVideo.mainPhotoUrl" size="70">
                                        <v-img
                                            :src="handleMainImgSrc(tributeVideo.mainPhotoUrl)"
                                            :lazy-src="handleMainImgSrc(tributeVideo.mainPhotoUrl)"
                                            alt="Avatar"
                                        />
                                    </v-avatar>
                                    <div style="display: flex; flex-direction: column; justify-content: center">
                                        <h3>Tribute Video</h3>
                                        <div class="d-flex">
                                            <p
                                                v-if="tributeVideo.firstName && tributeVideo.lastName"
                                                class="m-0"
                                                style="color: #999999"
                                            >
                                                {{ tributeVideo.firstName }} {{ tributeVideo.lastName }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="tribute-tabs">
                                    <v-tabs
                                        style="width: fit-content"
                                        color="#0f578b"
                                        class="mb-1"
                                        show-arrows
                                        center-active
                                        v-model="tabIndex"
                                    >
                                        <v-tab
                                            v-for="(tab, index) in tributeTabs"
                                            :key="index"
                                            :href="'#' + tab.value"
                                            >{{ tab.label }}</v-tab
                                        >
                                    </v-tabs>
                                </div>
                            </v-col>

                            <v-col v-if="formattedDate" cols="12">
                                <div class="text-center">
                                    <p>*All photos must be uploaded by</p>
                                    <h2>{{ formattedDate }},</h2>
                                    <h2>{{ formattedTime }}</h2>
                                </div>
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <v-tabs-items style="max-width: 100%; overflow: visible" v-model="tabIndex">
                            <v-tab-item value="gallery">
                                <floating-progress v-if="showUploadProgress" :progress="uploadProgress" />

                                <div class="d-flex flex-column align-items-center">
                                    <div class="dashboard-uploader-wrap">
                                        <photo-uploader-v2
                                            v-if="token"
                                            class="uploader"
                                            @progress="val => handleUploadProggess(val)"
                                            @loading="loading = true"
                                            @refresh-photos="loading = false"
                                            @upload-success-batch="result => handleUploadSuccessResult(result)"
                                            @upload-start="showUploadProgress = true"
                                            @all-uploads-complete="showUploadProgress = false"
                                            ref="familyPhotoUploader"
                                            :tributeToken="token"
                                            :eventId="eventId"
                                            :uploaderId="'family'"
                                            :requiresConfirm="render != null"
                                        >
                                        </photo-uploader-v2>
                                    </div>
                                </div>

                                <div class="scan-photos-link" v-if="isAndroid">
                                    <v-btn text color="primary" @click="scanPhotoModal = true" class="px-0">
                                        Do you have printed photos?
                                    </v-btn>
                                </div>

                                <div class="pt-4 d-flex justify-space-between align-end flex-wrap">
                                    <div>
                                        <strong
                                            ><span>
                                                All Slides ({{
                                                    tributeVideo.totalPhotos ? tributeVideo.totalPhotos : '0'
                                                }})
                                            </span></strong
                                        >
                                    </div>

                                    <v-menu left attach="" offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-bind="attrs" v-on="on" small depressed>
                                                <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" />
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item
                                                class="action-list-item d-flex justify-start"
                                                v-for="(item, index) in actionItems"
                                                :key="index"
                                                @click="item.callback"
                                            >
                                                <custom-tooltip :tooltipProps="{ top: true }">
                                                    <template v-slot:activator>
                                                        <v-list-item-title class="text-left d-flex align-items-center">
                                                            <font-awesome-icon
                                                                class="mr-3"
                                                                style="width: 1rem; height: 1rem"
                                                                :icon="item.icon"
                                                            ></font-awesome-icon>
                                                            {{ item.label }}
                                                        </v-list-item-title>
                                                    </template>

                                                    <template v-slot:content>
                                                        <span>{{ item.tooltip }}</span>
                                                    </template>
                                                </custom-tooltip>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>

                                <div class="d-flex flex-column align-items-center">
                                    <div v-if="event && service" class="gallery-section">
                                        <overlay-drag-drop
                                            ref="overlayUploader"
                                            @files-changed="files => handleOverlayFiles(files)"
                                        ></overlay-drag-drop>

                                        <TributePhotoGallery
                                            ref="gallery"
                                            :event="event"
                                            :service="service"
                                            :tributeToken="token"
                                            :scrollable="true"
                                            :mobile="isAndroid || isIOS"
                                        />
                                    </div>
                                </div>
                            </v-tab-item>

                            <!-- <v-tab-item value="stories">
                                <tribute-stories
                                    v-if="tributeVideo.storyEventId && token"
                                    :tributeToken="token"
                                    :mobile="os == 'Android' || os == 'iOS'"
                                ></tribute-stories>
                            </v-tab-item> -->

                            <v-tab-item value="templates">
                                <tribute-templates
                                    v-if="event && service && token"
                                    ref="templates"
                                    :playlistRedirect="playlistRedirect"
                                    :parentTabIndex="tabIndex"
                                    :event="event"
                                    :service="service"
                                    :token="token"
                                />
                            </v-tab-item>

                            <v-tab-item value="music">
                                <music-by-genre
                                    v-if="event && token"
                                    @refresh-sync="getSelectedSongs(tribute.tributeVideoId)"
                                    :token="token"
                                    :parentTabIndex="tabIndex"
                                    :event="event"
                                    :mobile="os == 'Android' || os == 'iOS'"
                                ></music-by-genre>
                            </v-tab-item>

                            <v-tab-item v-if="render" value="video">
                                <tribute-rendered-video-tab
                                    :render="render"
                                    :name="`${tributeVideo.firstName} ${tributeVideo.lastName}`"
                                />
                            </v-tab-item>
                        </v-tabs-items>
                    </div>
                </v-flex>
            </v-layout>
        </v-container>

        <v-dialog v-model="deletePhotoModal" max-width="350px">
            <v-card>
                <v-card-title> Delete Photo? </v-card-title>
                <v-card-text>This cannot be undone</v-card-text>
                <v-card-actions style="display: flex; justify-content: space-between">
                    <v-btn @click="cancelDelete" depressed>Cancel</v-btn>
                    <v-btn @click="deletePhoto(selectedPhoto)" color="error" depressed>Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog persistent v-model="unauthorizedError" max-width="600px">
            <v-card>
                <v-card-title>Error</v-card-title>
                <v-card-text>Unable to authorize access. Please visit the upload link to gain access.</v-card-text>
            </v-card>
        </v-dialog>

        <!-- Start: Photo Preview Modal -->
        <v-dialog v-model="previewModal" max-width="800">
            <v-card dark v-if="selectedItem != null" class="image-edit-card p-2">
                <v-btn
                    @click="previewModal = false"
                    fab
                    small
                    style="position: absolute; top: 10px; left: 10px; z-index: 5"
                    ><font-awesome-icon :icon="['far', 'x']"
                /></v-btn>
                <div class="image-edit-div">
                    <div v-if="selectedItem.mediaType == 1">
                        <video
                            controls
                            style="max-width: 100%; width: 100%; margin: 0; padding: 0"
                            :src="selectedItem.url"
                        ></video>
                    </div>
                </div>
            </v-card>
        </v-dialog>
        <!-- End: Photo Preview Modal -->

        <!-- START: Imgly editor -->
        <v-dialog eager v-model="imglyModal" fullscreen>
            <v-card dark>
                <div class="text-right pa-2">
                    <v-btn @click="imglyModal = false" dark text>Close</v-btn>
                </div>
                <div v-if="showImageError" class="image-error-div">
                    <p>An error occured while loading the image.</p>
                    <v-btn @click="handleDeleteEmit(imageToEdit)" color="error">Delete Image</v-btn>
                </div>
                <div>
                    <PhotoEditor
                        ref="photoEditor"
                        :photo="imageToEdit"
                        :visible="imglyModal"
                        :theme="imglyConfig.theme"
                        :layout="imglyConfig.layout"
                        :image-path="path"
                        :blank-image="blankImage"
                        :token="token"
                        :eventId="eventId"
                        @close="closeImglyModal"
                        @export-success="replacement => handleExport(replacement)"
                        @delete="handleDeleteEmit(imageToEdit)"
                        @revert-original="handleRevertEmit(imageToEdit)"
                        @move-start="handleMoveStart()"
                        @move-end="handleMoveEnd()"
                        @image-error="showImageError = true"
                    />
                </div>
            </v-card>
        </v-dialog>
        <!-- END: Imgly editor -->

        <!-- START: Download All Modal-->
        <branded-modal @close="downloadAllModal = false" max-width="400px" v-model="downloadAllModal">
            <template v-slot:title>
                <div>Download Collection</div>
            </template>

            <template v-slot:body>
                <v-card-text> This will download all {{ tributeVideo.totalPhotos + 1 }} photos. </v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn depressed @click="downloadAllModal = false">Cancel</v-btn>
                    <v-btn depressed @click="initCollectionDownload" dark color="#0d3d60">Download</v-btn>
                </v-card-actions>
            </template>
        </branded-modal>
        <!-- END: Download All Modal-->

        <share-upload-modal
            v-if="tributeVideo.deeplink && service && tributeVideo"
            ref="shareModal"
            :deeplink="tributeVideo.deeplink"
            :tributeVideo="tributeVideo"
            :service="service"
            @submit-invites="contacts => handleInviteSubmit(tribute.tributeVideoId, contacts)"
            @invalidEmail="email => handleInvalidEmail(email)"
        ></share-upload-modal>

        <!-- START: Scan Photos Modal -->
        <branded-modal @close="scanPhotoModal = false" max-width="400px" v-model="scanPhotoModal">
            <template v-slot:title>
                <div>Scan Printed Photos</div>
            </template>

            <template v-slot:body>
                <v-card-text>
                    If you have printed photos, you can easily scan them using Google's Scanner App. Download the app,
                    scan your photos, and save them to your device. Once saved, you can upload the digital versions
                    here.
                </v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn depressed @click="scanPhotoModal = false">Cancel</v-btn>
                    <v-btn depressed @click="handleAppRedirect" dark color="#0d3d60">Download</v-btn>
                </v-card-actions>
            </template>
        </branded-modal>
        <!-- END: Scan Photos Modal -->
    </div>
</template>
<script>
import logoIconSrc from '@/assets/images/logo_title.png';
import MobileRedirectBanner from '@/components/ManageService/Tribute/MobileRedirectBanner.vue';
import PhotoUploaderV2 from '@/components/ManageService/Tribute/PhotoUploaderV2.vue';

import MusicByGenre from '@/components/ManageService/Tribute/MusicByGenre.vue';
import ShareUploadModal from '@/components/ManageService/Tribute/ShareUploadModal.vue';
import TributePhotoGallery from '@/components/ManageService/Tribute/TributePhotoGallery.vue';
import TributeStories from '@/components/ManageService/Tribute/TributeStories.vue';
import TributeTemplates from '@/components/ManageService/Tribute/TributeTemplates.vue';
import AnimatedWavesBackground from '@/components/ui/AnimatedWavesBackground.vue';
import BrandedModal from '@/components/ui/BrandedModal.vue';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';
import FloatingProgress from '@/components/ui/FloatingProgress.vue';
import OverlayDragDrop from '@/components/ui/OverlayDragDrop.vue';
import PhotoEditor from '@/components/ui/PhotoEditor.vue';
import EventsService from '@/services/events.service';
import ServicesService from '@/services/services.service';
import TributePhotoService from '@/services/tributePhoto.service';
import TributeSongService from '@/services/tributeSong.service';
import TributeTemplateService from '@/services/tributeTemplate.service';
import TributeVideoService from '@/services/tributeVideo.service';
import Branch from '@/utilities/branch.js';
import { getDarkerShade } from '@/utilities/color.js';
import { getDictFromLocalStorage, setDictInLocalStorage } from '@/utilities/general';
import Cookies from 'js-cookie';
import JSZip from 'jszip';
import moment from 'moment-timezone';
import draggable from 'vuedraggable';
import { mapActions } from 'vuex';
import TributeRenderedVideoTab from './TributeRenderedVideoTab.vue';
export default {
    metaInfo: {
        title: 'Tribute Family Upload',
        meta: [
            {
                name: 'robots',
                content: 'noindex',
            },
        ],
    },
    data() {
        return {
            testMobile: false,

            message: 'upload page',
            eventId: 0,
            token: '',
            photos: [],
            logoIconSrc,
            totalPhotos: 0,
            pageNumber: 0,
            pageSize: 24,
            loading: false,
            tribute: null,
            awaitingPhotoLoad: false,
            selectedPhoto: null,
            deletePhotoModal: false,

            unauthorizedError: false,
            previewModal: false,
            formattedDate: null,
            formattedTime: null,
            dragging: false,
            imglyModal: false,
            imglyConfig: {
                theme: 'dark',
                layout: 'advanced',
                license: '{"owner":"Imgly Inc.","version":"2.4"}',
            },
            path: '',
            blankImage: false,
            showImageError: false,
            imageToEdit: null,
            selectedItem: null,

            appId: '1665247224',
            event: null,
            service: null,
            playlistRedirect: false,
            downloadAllModal: false,
            downloadProgress: {},
            overallProgress: 0,
            customStartColor: null,
            customEndColor: null,
            tabIndex: 'gallery',
            logo: null,
            render: null,
            uploadProgress: 0,
            actionItems: [
                {
                    label: 'Download Slides',
                    icon: 'fa-regular fa-arrow-down-to-bracket',
                    callback: this.openDownloadModal,
                    tooltip: 'Download collection',
                },
                {
                    label: 'Share',
                    icon: 'fa-regular fa-share-from-square',
                    callback: this.initShareModal,
                    tooltip: 'Share uploader link',
                },
                {
                    label: 'Add Slide',
                    icon: 'fa-regular fa-plus',
                    callback: this.initBlankImage,
                    tooltip: 'Create a Title Slide',
                },
                {
                    label: 'Upload Media',
                    icon: 'fa-regular fa-cloud-arrow-up',
                    callback: this.openFileUploader,
                    tooltip: 'Upload media',
                },
            ],
            tributeTabs: [],
            guidStorageName: 'ms-upload-guids',
            showUploadProgress: false,
            apiInstance: {},
            os: null,
            scanPhotoModal: false,
        };
    },
    components: {
        PhotoUploaderV2,
        draggable,
        PhotoEditor,
        TributePhotoGallery,
        OverlayDragDrop,
        JSZip,
        BrandedModal,
        ShareUploadModal,
        TributeStories,
        TributeTemplates,
        MusicByGenre,
        AnimatedWavesBackground,
        CustomTooltip,
        TributeRenderedVideoTab,
        FloatingProgress,
        MobileRedirectBanner,
    },
    watch: {
        loading() {
            if (!this.loading && this.awaitingPhotoLoad) {
                this.awaitingPhotoLoad = false;
                this.nextPagePhotos();
            }
        },
    },
    computed: {
        tributeVideo() {
            return this.$store.state.tributeVideo;
        },
        totalPages() {
            return Math.ceil(this.totalPhotos / this.pageSize);
        },
        isFamilyPage() {
            return this.$route.name === 'TributeFamilyPage';
        },
        isIOS() {
            if (!this.os) return false;

            return this.os.toLowerCase() == 'ios';
        },
        isAndroid() {
            console.log('os', this.os);

            if (!this.os) return false;
            return this.os.toLowerCase() == 'android';
        },
        displayRedirectBanner() {
            // return this.isAndroid || (this.isIOS && this.browser != 'Safari')
            return this.isAndroid || this.isIOS;
        },
        // photos() {
        //     return this.$store.state.tributeVideo.photos;
        // },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        ...mapActions('tributeVideo', [
            'updateTributeVideo',
            'updateTributeVideoPhotos',
            'updateTributeVideoSelectedPhotos',
            'updateTributeVideoSelectedTemplates',
            'updateTributeVideoSelectedSongs',
            'resetTributeVideoState',
        ]),
        handleUploadProggess(val) {
            if (val === 0 || val > this.uploadProgress) {
                this.uploadProgress = val;
            }
        },
        handleAppRedirect() {
            window.open('https://play.google.com/store/search?q=google+photo+scan&c=apps&hl=en_US&gl=US', '_blank');
        },
        setTributeTabs() {
            var tabs = [];
            if (this.isFamilyPage) {
                tabs = [
                    { label: 'Slides', value: 'gallery' },
                    { label: 'Theme', value: 'templates' },
                    { label: 'Music', value: 'music' },
                ];
            } else {
                tabs = [{ label: 'Slides', value: 'gallery' }];
            }

            this.tributeTabs = tabs;
        },

        handleUploadSuccessResult(result) {
            const newGuids = {};
            for (let i = 0; i < result.length; i++) {
                var record = result[i];
                newGuids[record.id] = record.guid;
            }

            this.addGuidsToLocal(newGuids);

            if (this.$refs.gallery) {
                this.$refs.gallery.onboardUploadHandler();
            }
        },
        async addGuidsToLocal(newGuids) {
            try {
                const uploads = (await getDictFromLocalStorage(this.guidStorageName)) || {};

                for (const id in newGuids) {
                    if (uploads[id] !== newGuids[id]) {
                        uploads[id] = newGuids[id];
                    }

                    setDictInLocalStorage(this.guidStorageName, uploads);
                }
            } catch (error) {
                console.log(error, 'Error adding GUID to local storage.');
            }
        },

        openFileUploader() {
            if (this.$refs.familyPhotoUploader) {
                this.$refs.familyPhotoUploader.openFileSelection();
            }
        },
        initShareModal() {
            this.$refs.shareModal.displayModal = true;
        },
        handleInvalidEmail(email) {
            this.showSnackbar({ message: `${email} is not a valid email`, color: 'error' });
        },
        async handleInviteSubmit(tributeId, contacts) {
            let message = `${this.service.funeralHomeName} has invited you to contribute to ${this.service.firstName} ${this.service.lastName}'s tribute video.`;

            let data = {
                emailList: contacts.emailList.join(','),
                phoneList: contacts.phoneList.join(','),
                url: contacts.url,
                subject: 'Tribute Invite',
                message: message,
                buttonText: 'Click Here',
            };

            if (this.$refs.shareModal);
            {
                this.$refs.shareModal.reset();
            }

            try {
                await this.apiInstance.tributeVideo.invite(tributeId, data);
                this.showSnackbar({ message: 'Invitations sent' });
            } catch (error) {
                console.log(error);
                if (error.response.status === 401) {
                    this.showSnackbar({ message: 'Unauthorized access', color: 'error' });
                } else {
                    this.showSnackbar({ message: 'Error sending invitations', color: 'error' });
                }
            }
        },
        openDownloadModal() {
            this.downloadAllModal = true;
        },
        async initCollectionDownload() {
            const photos = await this.getCollection(this.tributeVideo.id);
            await this.downloadImages(photos);
            this.downloadProgress = {};
            this.overallProgress = 0;
        },
        async getCollection(tributeId) {
            try {
                var resp = await this.apiInstance.tributePhoto.getCollectionUrls(tributeId);

                if (resp.data) {
                    return resp.data;
                }
            } catch (error) {
                console.log(error, 'error');
                this.showSnackbar({ message: 'Error getting total', color: 'error' });
            }
        },
        async downloadImages(photos) {
            try {
                this.loading = true;
                this.downloadAllModal = false;
                this.showCollectionDownloadProgress = true;

                const promises = photos.map(async image => {
                    const blob = await this.getImageData(image);

                    return { ...image, blob: blob };
                });

                const res = await Promise.all(promises);

                //Download multiple as zip folder
                if (photos.length > 1) {
                    const zip = new JSZip();

                    res.forEach((image, index) => {
                        zip.file(image.name, image.blob);
                    });

                    const zipFile = await zip.generateAsync({ type: 'blob' });

                    this.downloadBlob(zipFile, `${this.service.firstName} ${this.service.lastName} Tribute Photos`);
                    this.showSnackbar({ message: 'Photos downloaded' });
                } else {
                    this.downloadBlob(res[0].blob, res[0].name);
                    this.showSnackbar({ message: 'Photo downloaded' });
                }

                // this.loading = false;
            } catch (error) {
                console.log(error, 'zip error');
                this.showSnackbar({ message: 'Error downloading images', color: 'error' });
            }

            this.showCollectionDownloadProgress = false;
        },
        getImageData(photo, onProgressUpdate) {
            return new Promise((resolve, reject) => {
                this.loading = true;
                this.downloadProgress[photo.id] = 0;

                this.axios({
                    method: 'get',
                    url: photo.url,
                    responseType: 'blob',
                    onDownloadProgress: evt => {
                        const { loaded, total } = evt;
                        this.downloadProgress[photo.id] = Math.round((loaded / total) * 100);
                        this.calculateOverallProgress();
                    },
                })
                    // .then(resp => resp.blob())
                    .then(resp => {
                        resolve(resp.data);
                    })
                    .catch(error => {
                        console.log(error, 'error');
                        this.showSnackbar({ message: 'Error downloading image', color: 'error' });
                        this.loading = false;
                        reject();
                    });
            });
        },
        calculateOverallProgress() {
            const allProgress = Object.values(this.downloadProgress);
            const totalProgress = allProgress.reduce((acc, progress) => acc + progress, 0);
            this.overallProgress = Math.round(totalProgress / Object.keys(this.downloadProgress).length);
        },
        downloadBlob(file, name) {
            const blob = window.URL.createObjectURL(file);
            const anchor = document.createElement('a');

            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = name;
            document.body.appendChild(anchor);
            anchor.click();
            anchor.remove();
            window.URL.revokeObjectURL(blob);
        },
        handleMainImgSrc(url) {
            if (url && url.includes('/tribute-photos/')) {
                const imgPath = url.split('/tribute-photos/')[1];
                return process.env.VUE_APP_IMG_KIT_BASE + 'tr:w-500,h-500,fo-face/' + imgPath;
            }
        },
        handleOverlayFiles(files) {
            this.$refs.familyPhotoUploader.addFiles([...files]);
        },
        initBlankImage() {
            this.$refs.gallery.initBlankImage();
        },
        handleVideoPosterSrc(url) {
            // img kit docs: https://docs.imagekit.io/features/image-transformations

            //const imgKitBase = 'https://ik.imagekit.io/memoryshare/';

            if (url) {
                const imgPath = url.split('/tribute-photos/')[1];

                return process.env.VUE_APP_IMG_KIT_BASE + imgPath + '/ik-thumbnail.jpg';
            }

            return url;
        },
        handlePosterRefresh(item, index) {
            if (item.refreshKey > 5) {
                return;
            }

            setTimeout(async () => {
                try {
                    const response = await this.axios(this.handleVideoPosterSrc(item.url));

                    const videoEl = this.$refs[`video-${index}`][0];
                    videoEl.poster = this.handleVideoPosterSrc(item.url);
                    item.refreshKey++;
                } catch (error) {
                    if (error.response.data.includes('asset is too large')) {
                        const videoEl = this.$refs[`video-${index}`][0];
                        videoEl.removeAttribute('poster');
                        videoEl.load();
                    } else {
                        this.handlePosterRefresh(item, index);
                    }
                }
            }, 1000);
        },

        getOs() {
            // https://stackoverflow.com/questions/38241480/detect-macos-ios-windows-android-and-linux-os-with-js
            var userAgent = window.navigator.userAgent,
                platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
                macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
                windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
                iosPlatforms = ['iPhone', 'iPad', 'iPod'],
                os = null;

            if (macosPlatforms.indexOf(platform) !== -1) {
                os = 'MacOS';
            } else if (iosPlatforms.indexOf(platform) !== -1) {
                os = 'iOS';
            } else if (windowsPlatforms.indexOf(platform) !== -1) {
                os = 'Windows';
            } else if (/Android/.test(userAgent)) {
                os = 'Android';
            } else if (/Linux/.test(platform)) {
                os = 'Linux';
            }

            if (!os) return null;

            return os.toLowerCase();
        },
        handleOs() {
            if (this.$route.query.os) {
                this.os = this.overrideOs();
            }
            if (!this.os) {
                this.os = this.getOs();
            }

            if (this.os === 'android' || this.os === 'ios') {
                this.displaySplash = true;
            }
        },
        overrideOs() {
            const osOverride = this.$route.query.os.toLowerCase();
            let os = null;
            switch (osOverride) {
                case 'macos':
                    os = 'MacOS';
                    break;
                case 'ios':
                    os = 'iOS';
                    break;
                case 'windows':
                    os = 'Windows';
                    break;
                case 'android':
                    os = 'Android';
                    break;
                case 'linux':
                    os = 'Linux';
                    break;
                default:
                    os = null;
                    break;
            }

            if (!os) return null;

            return os.toLowerCase();
        },
        handleUppyOpen(ref) {
            if (ref != null) {
                this.$refs[ref].openFileSelection();
            }
        },
        handleExport(replacement = null) {
            this.imglyModal = false;
            this.imageEditModal = false;
            this.imageToEdit = null;
            this.path = '';

            if (replacement) {
                var found = this.photos.find(x => x.id == replacement.id);
                if (found) {
                    found.url = replacement.url;
                    found.loading = false;
                    found.originalPhoto = replacement.originalPhoto;
                }
            }
        },
        async quickOrder(id, order) {
            let found = this.photos.find(x => x.id == id);

            if (found.order == order) {
                this.showSnackbar({
                    message: `Photo already at ${order == 0 ? 'end' : 'start'}`,
                });
                return;
            }

            await this.updateOrder(id, order);
            this.getPhotos(this.eventId);
            this.imageEditModal = false;
        },
        handleMoveStart() {
            this.closeImglyModal();
            this.imageEditModal = false;
            this.quickOrder(this.imageToEdit.id, this.totalPhotos - 1);
            this.imageToEdit = null;
        },
        handleMoveEnd() {
            this.closeImglyModal();
            this.imageEditModal = false;
            this.quickOrder(this.imageToEdit.id, 0);
            this.imageToEdit = null;
        },
        async handleRevertEmit(photo) {
            await this.revertPhoto(photo);
            this.path = this.imageToEdit.url;
            this.closeImglyModal();
            this.imageEditModal = false;
        },
        async revertPhoto(photo) {
            if (photo.originalPhoto == null) {
                this.showSnackbar({ message: 'No photo to revert to' });
                return;
            }

            try {
                await this.apiInstance.tributePhoto.revertPhotoToOriginal(photo.id);
                this.revertConfirmModal = false;

                this.imageToEdit = null;
                this.imageToEdit = { ...resp.data, currentRotation: 0 };
                let found = this.photos.find(x => x.id == this.imageToEdit.id);
                if (found) {
                    let index = this.photos.indexOf(found);
                    this.photos.splice(index, 1, this.imageToEdit);
                }

                this.showSnackbar({ message: 'Original photo restored' });
            } catch (error) {}
        },
        handleDeleteEmit(photo) {
            this.closeImglyModal();
            this.deletePhoto(photo);
        },
        closeImglyModal() {
            this.imglyModal = false;
            this.blankImage = false;
        },
        imglyEdit(item) {
            this.path = item.url;
            this.imglyModal = true;
            this.imageEditModal = false;
            this.imageToEdit = item;
        },
        async handleChange(e) {
            let targetEl = null;
            var movedEl = e.moved.element;

            const targetOrder = this.totalPhotos - 1 - e.moved.newIndex;

            await this.updateOrder(movedEl.id, targetOrder);

            this.updatePhotoStore(0, this.pageSize);
        },
        async updateOrder(id, targetIndex) {
            try {
                return this.apiInstance.tributePhoto.updateOrder(id, targetIndex);
            } catch (error) {
                console.log(error, 'error');
            }
        },
        handleImgSrc(item) {
            if (item.url) {
                // img kit docs: https://docs.imagekit.io/features/image-transformations

                const imgPath = item.url.split('/tribute-photos/')[1];

                // return item.url;
                return process.env.VUE_APP_IMG_KIT_BASE + 'tr:w-250,h-250/' + imgPath;
            }
        },
        handlePreview(item) {
            this.selectedItem = item;
            this.previewModal = true;
        },
        cancelDelete() {
            this.deletePhotoModal = false;
            this.selectedPhoto = null;
        },
        deletePhotoStep1(item) {
            this.selectedPhoto = item;
            this.deletePhotoModal = true;
        },
        deletePhoto(item) {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .delete(process.env.VUE_APP_API + `/TributeVideoPhoto/${item.id}`)
                .then(res => {})
                .catch(error => {
                    console.log(error, 'error');
                })
                .finally(() => {
                    this.deletePhotoModal = false;
                    this.imageEditModal = false;
                    this.imageToEdit = null;
                });
        },
        async getTributeInfo(slug) {
            try {
                this.loading = true;
                var resp = await this.apiInstance.tributeVideo.getSharedUploadInfo(slug);

                if (resp.data) {
                    let tributeData = {
                        ...resp.data,
                        mainPhotoUrl: resp.data.photoUrl,
                        id: resp.data.tributeVideoId,
                    };
                    this.updateTributeVideo(tributeData);

                    if (resp.data.funeralHomeBrandColor) {
                        this.setAnimatedBackgroundColors(resp.data.funeralHomeBrandColor);
                    }

                    if (resp.data.funeralHomeLogo) {
                        this.logo = resp.data.funeralHomeLogo;
                    }

                    if (resp.data.render != null) {
                        this.render = resp.data.render;

                        this.tributeTabs.unshift({ label: 'Video', value: 'video' });
                        this.tabIndex = 'video';
                    }

                    return resp.data;
                }
            } catch (error) {
                console.log(error);
                if (err.response.status == 401) {
                    this.unauthorizedError = true;
                }
            } finally {
                this.loading = false;
            }
        },
        setAnimatedBackgroundColors(baseColor) {
            const darkerShade = getDarkerShade(baseColor, 0.2);

            this.customStartColor = baseColor;
            this.customEndColor = darkerShade;
        },
        async getService(id) {
            try {
                this.loading = true;
                var resp = await this.apiInstance.services.get(id);
                return resp.data;
            } catch (error) {
                if (err.response.status == 401) {
                    this.unauthorizedError = true;
                }
            } finally {
                this.loading = false;
            }
        },
        async getEvent(id) {
            try {
                var resp = await this.apiInstance.events.get(id);

                if (resp.data) {
                    return resp.data;
                }
            } catch (error) {
                console.log(error, 'error');
                if (error.response.status == 401) {
                    this.unauthorizedError = true;
                }
            }
        },

        async getPhotos(id, params) {
            try {
                var resp = await this.apiInstance.tributePhoto.getPhotos(id, params);

                return resp.data;
            } catch (error) {
                console.log(error, 'error');
            }
        },
        nextPagePhotos() {
            this.pageNumber += 1;
            this.updatePhotoStore(this.pageNumber, this.pageSize);
        },
        async updatePhotoStore(pageNum, pageSize) {
            this.loading = true;

            const result = await this.getPhotos(this.eventId, {
                pageNumber: pageNum,
                pageSize: pageSize,
            });

            const newList = result.photos.map(img => {
                return { ...img, loading: false };
            });

            if (pageNum > 0) {
                const combinedList = this.photos.concat(newList);
                this.photos = combinedList;
            } else {
                this.photos = newList;
            }

            this.totalPhotos = result.total;
            this.loading = false;
        },
        initObserver() {
            let options = {
                root: null,
                rootMargin: '0px',
                treshold: 1.0,
            };

            this.observer = new IntersectionObserver(this.handleIntersect, options);
            let target = document.querySelector('#infinite-scroll-target');
            this.observer.observe(target);
        },
        handleIntersect(entries, observer) {
            let targetEntry = entries.find(x => x.target.id == 'infinite-scroll-target');
            if (targetEntry && targetEntry?.isIntersecting) {
                if (this.totalPhotos > this.photos.length) {
                    if (this.loading) {
                        this.awaitingPhotoLoad = true;
                    } else {
                        this.nextPagePhotos();
                    }
                }
            }
        },
        initBranchSession() {
            return new Promise((resolve, reject) => {
                Branch.initSession((err, data) => {
                    if (err) {
                        reject(err);
                        return;
                    }
                    if (data) {
                        resolve(data.data_parsed);
                    } else {
                        resolve(null);
                    }
                });
            });
        },
        setCookie(cookieName, value) {
            Cookies.set(cookieName, value, {
                expires: 30,
            });
        },
        getCookie(cookieName) {
            const myCookie = Cookies.get(cookieName);

            if (myCookie) {
                return myCookie;
            } else {
                return null;
            }
        },
        deleteCookie(cookieName) {
            Cookies.remove(cookieName);
        },
        removeQueryParams() {
            let url = window.location.href.split('?')[0];
            window.history.replaceState({}, document.title, url);
        },

        addAppleSmartBanner() {
            const metaTag = document.createElement('meta');
            metaTag.name = 'apple-itunes-app';
            metaTag.content = `app-id=${this.appId}`;
            metaTag.id = 'apple-itunes-app-meta';
            document.getElementsByTagName('head')[0].appendChild(metaTag);
        },
        removeAppleSmartbanner() {
            const metaTag = document.getElementById('apple-itunes-app-meta');
            if (metaTag) {
                document.getElementsByTagName('head')[0].removeChild(metaTag);
            }
        },

        async getSelectedSongs(id) {
            try {
                this.loading = true;
                var resp = await this.apiInstance.tributeSong.getSelected(id);
                if (resp.data) {
                    this.updateTributeVideoSelectedSongs(resp.data);
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        async getSelectedTemplates(id) {
            try {
                var resp = await this.apiInstance.tributeTemplate.getSelected(id);
                if (resp.data) {
                    this.updateTributeVideoSelectedTemplates(resp.data.templates);
                }
            } catch (error) {
                console.log(error, 'error');
            }
        },

        initApiInstance(token = null) {
            this.apiInstance.tributeVideo = TributeVideoService(token);
            this.apiInstance.tributeSong = TributeSongService(token);
            this.apiInstance.tributePhoto = TributePhotoService(token);
            this.apiInstance.tributeTemplate = TributeTemplateService(token);
            this.apiInstance.services = ServicesService(token);
            this.apiInstance.events = EventsService(token);
        },
        async loadInitialData() {
            this.tribute = await this.getTributeInfo(this.$route.params.slug);
            if (!this.tribute) return;

            if (this.tribute.eventId) {
                this.eventId = this.tribute.eventId;
                this.event = await this.getEvent(this.eventId);
            }

            if (this.tribute.serviceId) {
                this.service = await this.getService(this.tribute.serviceId);
            }

            this.getSelectedSongs(this.tribute.tributeVideoId);
            this.getSelectedTemplates(this.tribute.tributeVideoId);

            if (this.tribute.deadline && this.tribute.deadline != '0001-01-01T00:00:00') {
                this.formattedDate = moment.utc(this.tribute.deadline).local().format('MMMM Do YYYY');
                this.formattedTime = moment.utc(this.tribute.deadline).tz(moment.tz.guess()).local().format('LT z');
            }

            this.updatePhotoStore(this.pageNumber, this.pageSize);
        },

        async getTributeToken(slug, pin) {
            this.apiInstance.tributeVideo = TributeVideoService(null);

            try {
                const resp = await this.apiInstance.tributeVideo.getTributeToken(slug, pin);

                if (resp.data) {
                    return resp.data;
                }
            } catch (error) {}
        },
        async setAuthToken() {
            var resp = await this.getTributeToken(this.$route.params.slug, this.$route.params.pin);
            this.token = resp.token;
            this.role = resp.role;
        },
        async handleBranchData() {
            try {
                const deepLinkData = await this.initBranchSession();
            } catch (error) {
                console.log('Error initializing branch session', error);
            }
        },
        checkBrowser() {
            const userAgent = navigator.userAgent;

            if (/MSIE|Trident/.test(userAgent)) {
                this.browser = 'Internet Explorer';
            } else if (/Edge/.test(userAgent)) {
                this.browser = 'Edge';
            } else if (/Firefox/.test(userAgent)) {
                this.browser = 'Firefox';
            } else if (/CriOS/.test(userAgent)) {
                this.browser = 'Chrome'; // Chrome on iOS
            } else if (/Chrome/.test(userAgent)) {
                this.browser = 'Chrome';
            } else if (/Safari/.test(userAgent)) {
                this.browser = 'Safari';
            } else {
                this.browser = 'Unknown';
            }
        },
    },

    async mounted() {
        this.handleOs();
        this.checkBrowser();
        this.addAppleSmartBanner();
        this.setTributeTabs();

        //this.handleBranchData();
        this.removeQueryParams();

        await this.setAuthToken();
        this.initApiInstance(this.token);
        this.loadInitialData();
    },
    beforeDestroy() {
        this.removeAppleSmartbanner();
    },
    sockets: {
        NotifyTributeSongsUpdate(data) {
            if (data.tributeId == this.tribute.tributeVideoid) {
                this.getSelectedSongs(this.tribute.tributeVideoid);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
// .blue-background {
//     background: url('../../assets/images/animated-background-blue.svg') no-repeat 0 -16rem;
// }
.upload-page-wrapper {
    background-color: transparent;
    position: relative;
    max-width: 100vw;
    height: 100vh;
    overflow-x: hidden;

    .action-list-item {
        cursor: pointer;
    }

    .logo-wrap {
        border: none;
        max-width: 100px;
        max-height: 100px;
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 1;
        object-fit: contain;
        padding: 10px;
    }

    .action-list-item:hover {
        background-color: #f8f8f8;
    }

    .dashboard-uploader-wrap {
        background-color: #f8f8f8;
        padding: 10px;
        border-radius: 5px;
        width: 100%;
        max-width: 900px;
        display: flex;
        justify-content: center;
    }

    .gallery-section {
        width: 100%;
        // max-width: 1200px;
        // padding: 0 44px;
    }

    // .gallery-video {
    //     aspect-ratio: 1;
    //     object-fit: cover;
    //     max-width: 100%;
    //     border-radius: 5px;
    // }

    .hero {
        min-width: 100%;
        // background: linear-gradient(180deg, #0d3d60 40%, #ffffff 40%);
        // border: 1px solid green;
        // background-color: #ffffff;
        padding: 60px 0;

        background-size: cover;
        background-position: center;
        position: relative;
    }

    .card-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0 200px;
    }
    .float-card {
        // border-radius: 40px;
        border-radius: 4px;
        padding: 12px;
        background-color: white;
    }
    .card-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 4px;
    }

    .gallery-content {
        margin-top: 20px;
        max-width: 80%;
    }
    // .delete-action-btn {
    //     position: absolute;
    //     z-index: 2;
    //     top: 10px;
    //     right: 10px;
    //     opacity: 0;
    //     height: 20px !important;
    //     width: 20px !important;
    // }

    // .family-gallery-photo {
    //     // border-radius: 10px;
    //     border-radius: 4px;
    //     cursor: pointer;
    //     width: 100%;
    //     height: auto;
    //     max-width: 300px;
    //     max-height: 300px;
    // }

    // .family-gallery-photo:hover ~ .delete-action-btn {
    //     opacity: 0.4;
    //     // border: 2px solid red;
    // }
    // .delete-action-btn:hover {
    //     opacity: 1;
    // }

    .photo-wrap {
        display: flex;
        justify-content: center;
        position: relative;
    }
    .family-uploader {
        border-radius: 40px;

        #drop-zone {
            border-radius: 40px;
            height: 150px;
            border: 2px dashed #dbe2e8;
        }
        #drop-zone div {
            border-radius: 40px;
        }
        .uppy-DragDrop--isDragDropSupported {
            border-radius: 40px;
        }
        .uppy-DragDrop-container {
            border-radius: 40px !important;
        }
    }

    .animated-bg-comp {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 0;
        min-width: 100%;
        max-width: 100%;
        overflow: hidden;
    }

    .tribute-header {
        display: flex;
        justify-content: space-between;
    }

    .tribute-tabs {
        max-width: 100% !important;
        overflow: auto;
    }

    .scan-photos-link {
        margin: 16px 0 0 0;

        .v-btn {
            text-transform: none;
            letter-spacing: normal;
            text-decoration: underline;
        }
    }
}

@media (max-width: 1250px) {
    .upload-page-wrapper {
        .card-wrap {
            padding: 0 120px;
        }
    }
}

@media (max-width: 1024px) {
    /* Styles for mobile devices and iPads */
    .upload-page-wrapper {
        .download-banner {
            display: flex;
        }
        .hero {
            padding-bottom: 0;
        }

        .family-gallery-photo {
            max-width: 400px;
            max-height: 400px;
        }

        .card-wrap {
            padding: 0 40px;
        }
    }
}

@media only screen and (max-width: 780px) {
    .upload-page-wrapper {
        .tribute-header {
            display: flex;
            flex-direction: column;
        }

        .tribute-tabs {
            align-self: center;
        }
    }
}

@media only screen and (max-width: 510px) {
    .upload-page-wrapper {
        .gallery-section {
            padding: 0 16px;
        }

        .card-wrap {
            padding: 0;
        }
    }
}
</style>
