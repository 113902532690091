import Sortable, { MultiDrag } from 'sortablejs';

// Mount the MultiDrag plugin globally
Sortable.mount(new MultiDrag());

const sortableHelper = {
    initSortable(container, options = {}) {
        if (!container) throw new Error('Container is required to initialize Sortable.');

        const sortable = Sortable.create(container, {
            animation: 300,
            multiDrag: true,
            multiDragKey: 'del',
            avoidImplicitDeselect: true,
            selectedClass: 'sortable-selected',
            ghostClass: 'sortable-ghost',
            dragClass: 'sortable-drag',
            filter: '.ignore-draggable',
            ...options,
        });

        return sortable;
    },

    /**
     * Parses an element's ID to extract the slide ID.
     * @param {HTMLElement} element - The element to parse.
     * @returns {Number|null} The parsed slide ID or null if invalid.
     */
    parseElementId(element, idBase) {
        if (!element || !element.id) return null;
        const id = parseInt(element.id.replace(idBase, ''), 10);
        return Number.isNaN(id) ? null : id;
    },

    /**
     * Deselects all selected elements within a container.
     * @param {Sortable} sortableInstance - The sortable instance.
     */
    deselectAll(sortableInstance) {
        const container = sortableInstance.el;

        if (!container) return;
        const selectedItems = container.querySelectorAll('.sortable-selected');
        selectedItems.forEach(item => Sortable.utils.deselect(item));
    },

    /**
     * Deselects all selected elements within a container.
     * @param {Sortable} sortableInstance - The sortable instance.
     */
    getSelectedElements(sortableInstance) {
        const container = sortableInstance.el;

        if (!container) return [];

        return container.querySelectorAll('.sortable-selected');
    },

    syncSelected(sortableInstance, selectedIds, querySelectorBase) {
        const container = sortableInstance.el;
        if (!container) return;

        this.deselectAll(sortableInstance);

        // Reselect dom elements in Sortable.js
        selectedIds.forEach(id => {
            const element = container.querySelector(`${querySelectorBase}${id}`);
            if (element) Sortable.utils.select(element);
        });
    },
};

export default sortableHelper;
