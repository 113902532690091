<template>
    <div v-if="isOpen" class="modal-container">
      <div class="modal-overlay" @click="closeModal"></div>
      <div class="modal-content">
        <div class="modal-banner">
          <div class="banner-text">Modify Theme</div>
          <div class="close-button" @click="closeModal">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 15L15 5M5 5L15 15" stroke="#6B7280" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </div>
  
        <div class="modal-body">
          <div class="preview-container">
            <div class="video-frame">
              <CreatomateSmallPreview :source="previewSource" :modifications="previewMods" />
            </div>
          </div>
  
          <div class="mods-form">
            <label>Font</label>
            <google-fonts-drop-down v-show="isOpen" v-model="tempFont" class="font-drop-down"
              @font-changed="handleFontChange" ref="fontDropdown"></google-fonts-drop-down>
  
            <div class="form-group-row">
              <div class="form-group">
                <label>First Name</label>
                <input v-model="tempModifications['FirstName'].value" type="text" class="text-input"
                  @input="checkForChanges" />
              </div>
  
              <div class="form-group">
                <label>Last Name</label>
                <input v-model="tempModifications['LastName'].value" type="text" class="text-input"
                  @input="checkForChanges" />
              </div>
            </div>
  
            <div class="form-group">
              <label>Birth Year</label>
              <input v-model="tempModifications['BirthDate'].value" type="text" class="text-input"
                @input="checkForChanges" />
            </div>
  
            <div class="form-group">
              <label>Death Year</label>
              <input v-model="tempModifications['DeathDate'].value" type="text" class="text-input"
                @input="checkForChanges" />
            </div>
          </div>
        </div>
  
        <div class="modal-footer">
          <v-btn class="save-button" :disabled="!hasChanges" :style="{ opacity: hasChanges ? 1 : 0.9 }"
            @click="saveChanges">
            Save Changes
            <v-icon right> mdi-chevron-right </v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import CreatomateSmallPreview from '@/components/Tribute/Themes/CreatomatePreview.vue';
  import GoogleFontsDropDown from '@/components/Tribute/Themes/GoogleFontsDropdown.vue';
  import { mapActions } from 'vuex';
  
  export default {
    name: 'ModifyThemeModal',
    components: {
      CreatomateSmallPreview,
      GoogleFontsDropDown,
    },
    props: {
      isOpen: {
        type: Boolean,
        required: true,
      },
      tributeId: {
        type: Number,
        required: true,
      },
      previewSource: {
        type: String,
        required: true,
      },
      previewMods: {
        type: String,
        required: true,
      },
      currentFont: {
        type: Object,
        required: true,
      },
      currentModifications: {
        type: Array,
        required: true,
      },
    },
    async mounted() {
      if (this.isOpen) {
        this.$nextTick(() => {
          this.ensureFontsLoaded(() => {
            if (this.$refs.fontDropdown && this.$refs.fontDropdown.setFont) {
              this.$refs.fontDropdown.setFont(this.tempFont.family);
            }
          });
        });
      }
    },
    watch: {
  
      currentModifications: {
        immediate: true,
        handler(newVal) {
          if (newVal.length > 0) {
            this.initializeTempModifications();
          }
        },
      },
      isOpen(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.ensureFontsLoaded(() => {
              if (this.$refs.fontDropdown && this.$refs.fontDropdown.setFont) {
                this.$refs.fontDropdown.setFont(this.currentFont.family);
              }
            });
          });
        }
      },
    },
  
    data() {
      return {
        tempFont: this.currentFont,
        tempModifications: null,
        hasChanges: false,
      };
    },
    methods: {
      ...mapActions(['showSnackbar']),
      ...mapActions('tributeVideo', ['updateTributeVideo', 'updateTributeVideoSelectedTemplates']),
      initializeTempModifications() {
        const expectedNames = ['FirstName', 'LastName', 'BirthDate', 'DeathDate'];
        this.tempModifications = expectedNames.reduce((acc, name) => {
          const mod = this.currentModifications.find(m => m.name === name);
          acc[name] = mod ? { key: mod.key, value: mod.value } : { key: '', value: '' };
          return acc;
        }, {});
      },
      closeModal() {
        this.$emit('close');
      },
  
      saveChanges() {
        const updatedModifications = {};
  
        for (const [name, mod] of Object.entries(this.tempModifications)) {
          const original = this.currentModifications.find((m) => m.key === mod.key)?.value;
          if (mod.value !== original) {
            updatedModifications[mod.key] = mod.value;
          }
        }
  
        let customFont = null;
        if (this.tempFont.family !== this.currentFont.family) {
          customFont = {
            family: this.tempFont.family,
            source: this.tempFont.files.regular.replace('http:', 'https:'),
            style: 'normal',
            weight: 700,
          };
        }
  
        this.$emit('save', { modifications: updatedModifications, font: customFont });
        this.hasChanges = false;
      },
      checkForChanges() {
        this.hasChanges = Object.entries(this.tempModifications).some(([key, mod]) => {
          const original = this.currentModifications.find((m) => m.key === mod.key)?.value;
          return mod.value !== original;
        }) || this.tempFont.family !== this.currentFont.family;
      },
  
      handleFontChange(font) {
        this.tempFont = font;
        this.checkForChanges();
      },
      ensureFontsLoaded(callback) {
        const dropdown = this.$refs.fontDropdown;
        if (dropdown && dropdown.fonts.length > 0) {
          callback();
        } else {
          const interval = setInterval(() => {
            if (dropdown && dropdown.fonts.length > 0) {
              clearInterval(interval);
              callback();
            }
          }, 50);
        }
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .modal-container {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    border: 1px solid #D1D5DB;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9998;
  }
  
  .modal-content {
    position: relative;
    width: auto;
    min-width: 50vw;
    max-width: 98vw;
    height: 99vh;
    background: white;
    z-index: 9999;
  }
  
  .modal-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    max-height: 5vh;
    background: white;
    border-bottom: 1px solid #D1D5DB;
  }
  
  .preview-container {
    padding: 10px 100px 0px 100px;
  }
  
  .video-frame {
    max-width: 800px;
    width: 100%;
    background-color: #ffffff;
    padding: 12px;
    border-radius: 8px;
    top: 0;
    position: relative;
    transition: top 400ms ease-in-out;
    box-shadow: 0px 7.47px 7.47px -3.74px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 14.95px 18.69px -3.74px rgba(0, 0, 0, 0.1);
  }
  
  .mods-form {
    margin-top: -80px;
    max-height: 50vh;
    padding: 0px 10px;
  }
  
  .banner-text {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #374151;
  }
  
  .close-button {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #D1D5DB;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 0px 1px 2px 0px #0000000D;
  }
  
  .modal-body {
    flex: 1;
    padding: 0px 16px;
    background: #F9FAFB;
    overflow-y: auto;
  }
  
  label {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #374151;
  }
  
  .form-group-row {
    display: flex;
    justify-content: space-between;
    gap: 16px;
  }
  
  .form-group {
    flex: 1;
  }
  
  .text-input {
    width: 100%;
    height: 38px;
    padding: 8px;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  }
  
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    height: 6vh;
    background: #f9fafb;
  }
  
  .save-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: $primary-orange !important;
    width: 206px;
    height: 38px;
    padding: 9px 15px 9px 17px;
    border: none;
    border-radius: 6px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    box-shadow: 0px 1px 2px 0px #0000000D;
    cursor: pointer;
    color: white !important;
    text-transform: none;
    letter-spacing: normal;
  }
  
  .save-button[disabled] {
    cursor: not-allowed;
  }
  
  .save-button svg {
    stroke: white;
  }
  </style>
  