import { render, staticRenderFns } from "./ServiceSuper.vue?vue&type=template&id=d4e4265c&scoped=true&"
import script from "./ServiceSuper.vue?vue&type=script&lang=js&"
export * from "./ServiceSuper.vue?vue&type=script&lang=js&"
import style0 from "./ServiceSuper.vue?vue&type=style&index=0&id=d4e4265c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4e4265c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VCheckbox,VSelect,VTextField})
